import { FC, memo, Dispatch, SetStateAction, useEffect } from 'react';
import Button from '../../meeting-rooms/controls/button';
import Settings from '../../meeting-rooms/controls/menu-settings';
import MenuButton from '../../meeting-rooms/controls/menu-button';
import useLocalAudioTrack from "../../../../../../hooks/use-local-audio-track";

interface Props {
    setError: Dispatch<SetStateAction<any>>
    setTrack?: Dispatch<SetStateAction<any>>
}

const AudioControl: FC<Props> = ({ setError }) => {
    const { track, state, handleTrack, devices, selected, handleChange } = useLocalAudioTrack();

    useEffect(() => {
        setError((prev: any) => {
            if (state === "prompt") return { ...prev, mic: "Waiting for microphone permission" }
            if (state === "denied") return { ...prev, mic: "Please allow access to your microphone" }
            return { ...prev, mic: null }
        });
    }, [setError, state]);

    if (devices.length) {
        return (
            <MenuButton onClick={handleTrack} icon={track ? "mic" : "mic-mute"}>
                <Settings {...{ title: "Select a Microphone", devices, selected, handleChange }} />
            </MenuButton>
        )
    } else {
        return (
            <Button style={{ background: "#C0372A" }} disabled={true} icon="mic-mute" />
        )
    }
}

export default memo(AudioControl);