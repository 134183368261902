import { FC, memo } from 'react';
import Button from '../../../../../button';
import Modal, { Title, Text } from '../../../../../modal';

interface Props {
    close: () => void
}

const UserInteractionRequired: FC<Props> = ({ close }) => {
    return (
        <Modal>
            <Title>User interaction required!</Title>
            <Text>User need to interact with the website before able to play the video or audio.</Text>
            <Button onClick={close} style={{ marginTop: "20px" }}>Ok</Button>
        </Modal>
    )
}

export default memo(UserInteractionRequired);