import { useState } from 'react';

const useValidation = (submit: () => Promise<void>) => {
    const [isSubmitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState<any>({});
    const [values, setValues] = useState({ email: "", first_name: "", last_name: "", tc: false });


    const handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
        setValues((prevState) => {
            return { ...prevState, [name]: name === "tc" ? !prevState.tc : value }
        });

        if (errors[name]) {
            setErrors((prevState: any) => {
                delete prevState[name];
                return prevState;
            });
        }
    }

    const validate = () => {
        const errors = {} as any
        if (!values.tc) errors.tc = true
        if (!values.email) errors.email = "Email is required."
        if (!values.first_name) errors.first_name = "First Name is required."
        if (!values.last_name) errors.last_name = "Last Name is required."
        return errors;
    }

    const handleSubmit = () => {
        setErrors({});
        setSubmitting(true);
        const errors = validate();
        if (Object.values(errors).length) {
            setErrors(errors);
            setSubmitting(false);
        } else {
            submit().catch((err: any) => {
                const message = err?.response?.data?.message || err?.message || "Sorry an Error Occurred. Please Try Again."
                if (err?.response?.data?.errors) {
                    setErrors(err.response.data.errors);
                } else {
                    setErrors({ message });
                }
            }).finally(() => setSubmitting(false));
        }
    }

    return [values, isSubmitting, errors, handleChange, handleSubmit]
}

export default useValidation;