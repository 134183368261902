import { RootState } from '../../index';
import usePermission from './use-permission';
import { useSelector, shallowEqual } from 'react-redux';
import { useEffect, useState } from 'react';
import useGetUserDevices, { IUserDevices } from './use-get-user-devices';
import { createLocalVideoTrack, LocalVideoTrack } from 'twilio-video';

interface VideoTrack extends IUserDevices {
    handleTrack: () => void
    track: LocalVideoTrack | null
    state: PermissionStatus["state"]
}

const useLocalVideoTrack = (): VideoTrack => {
    const [state] = usePermission("camera");
    const [create, setCreate] = useState<boolean>(true);
    const [track, setTrack] = useState<LocalVideoTrack | null>(null);
    const { devices, selected, handleChange } = useGetUserDevices({ type: "videoinput", state });
    const [type, is_breakout_room] = useSelector(({ user, event }: RootState) => ([user.type, event?.event?.breakout_room]), shallowEqual);

    useEffect(() => {
        if (create && state !== "denied") {
            const width = (type === 'participant' && !is_breakout_room) ? 400 : 1920;
            const height = (type === 'participant' && !is_breakout_room) ? 400 : 1080;
            const constrains = { width, height, frameRate: 30, deviceId: {} }
            if (selected) constrains.deviceId = { exact: selected }
            createLocalVideoTrack(constrains).then((track) => {
                setTrack((prevTrack) => {
                    if (prevTrack) prevTrack.stop();
                    return track;
                });
            }).catch((error) => console.error(error.message));
            return () => {
                setTrack((prevTrack) => {
                    if (prevTrack) prevTrack.stop();
                    return null;
                });
            }
        }
    }, [create, type, state, is_breakout_room, selected]);

    const handleTrack = () => setCreate(!create);

    return { track, state, devices, selected, handleTrack, handleChange }
}

export default useLocalVideoTrack;