import { FC, memo } from 'react';
import styles from './styles.module.css';
import { Link } from 'react-router-dom';
import { domain } from '../../config.json';
import SharedStudiosLogo from '../../images/shared-studios-logo.png';

interface Props {
    logo: string
    orgId: string
}

const Header: FC<Props> = ({ logo, orgId }) => {
    return (
        <div className={styles.header}>
            <Link to={`/${orgId}`}>
                <img className={styles.logo} alt={orgId} src={`${domain}/${logo}`} />
            </Link>
            <p>in partnership with</p>
            <img className={styles.logo} src={SharedStudiosLogo} alt="" />
        </div>
    )
}

export default memo(Header);