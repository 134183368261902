import axios from '../axios';
import Video, { RemoteParticipant, LocalParticipant } from 'twilio-video';
import { Dispatch } from 'redux';
import { JOINED_MEETING, DOMINANT_SPEAKER_CHANGED } from '../redux/reducers/meeting-reducer/types';
import { DispatchJoinMeeting, DispatchDominantSpeakerChanged } from '../redux/reducers/meeting-reducer/types';
import { PARTICIPANT_JOINED, PARTICIPANT_LEFT, UPDATE_PARTICIPANT } from '../redux/reducers/meeting-reducer/types';
import { DispatchDisconnectedFromMeeting, DISCONNECTED_FROM_MEETING } from '../redux/reducers/meeting-reducer/types';
import { DispatchParticipantJoined, DispatchParticipantLeft, DispatchUpdateParticipant } from '../redux/reducers/meeting-reducer/types';

export const joinMeeting = (isBreakoutRoom: boolean) => {
    return (dispatch: Dispatch<DispatchJoinMeeting>) => {
        dispatch({
            type: JOINED_MEETING,
            payload: axios.post('/rooms/join').then(({ data: { token, users } }) => {
                return Video.connect(token, {
                    audio: false,
                    video: false,
                    // logLevel: 'debug',
                    bandwidthProfile: {
                        video: {
                            mode: "collaboration",
                            maxSubscriptionBitrate: 0,
                            dominantSpeakerPriority: "high",
                            contentPreferencesMode: "auto",
                            clientTrackSwitchOffControl: "manual",
                        }
                    },
                    maxAudioBitrate: 16000,
                    dominantSpeaker: !isBreakoutRoom,
                    preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
                    networkQuality: { local: 1, remote: 1 }
                }).then((room) => {
                    return { room, users }
                });
            })
        })
    }
}

export const dominantSpeakerChanged = (rp: RemoteParticipant) => {
    return (dispatch: Dispatch<DispatchDominantSpeakerChanged>) => {
        dispatch({ type: DOMINANT_SPEAKER_CHANGED, payload: rp })
    }
}

export const participantJoined = (payload: RemoteParticipant) => {
    return (dispatch: Dispatch<DispatchParticipantJoined>) => {
        dispatch({ type: PARTICIPANT_JOINED, payload });
    }
}

export const participantLeft = (payload: RemoteParticipant) => {
    return (dispatch: Dispatch<DispatchParticipantLeft>) => {
        dispatch({ type: PARTICIPANT_LEFT, payload });
    }
}

export const updateParticipant = (payload: RemoteParticipant | LocalParticipant) => {
    return (dispatch: Dispatch<DispatchUpdateParticipant>) => {
        dispatch({ type: UPDATE_PARTICIPANT, payload });
    }
}

export const disconnectedFromMeeting = () => {
    return (dispatch: Dispatch<DispatchDisconnectedFromMeeting>) => {
        dispatch({ type: DISCONNECTED_FROM_MEETING });
    }
}