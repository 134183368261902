import { FC, memo } from 'react';
import styles from './styles.module.css';
import Button from '../../../../../../button';
import { RootState } from '../../../../../../../../index';
import Modal, { Title, Text } from '../../../../../../modal';
import { updateEventStatus } from '../../../../../../../action/event';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

interface Props {
    close: () => void
}

const EndCallModal: FC<Props> = ({ close }) => {
    const dispatch = useDispatch();
    const { loading, error } = useSelector(({ event }: RootState) => {
        return {
            error: event.event?.error,
            loading: event.event?.loading,
        }

    }, shallowEqual)

    const handleEndEvent = () => {
        dispatch(updateEventStatus({
            is_started: false,
            is_previous_event: true,
            is_message_enabled: false,
            is_registration_open: false
        }))
    }

    return (
        <Modal>
            <Title>Sure you want to end the event?</Title>
            <Text>Everyone will be disconnected and this event will be marked a previous event</Text>
            <div className={styles.modal_buttons}>
                <Button onClick={close} style={{ background: "#E1EDCC", color: "#545050" }} disabled={loading}>Cancel</Button>
                <Button onClick={handleEndEvent} style={{ background: "#C0372A" }} disabled={loading} loading={loading}>End Event</Button>
            </div>
            {error && <p className={styles.error_message}>{error}</p>}
        </Modal>
    )
}

export default memo(EndCallModal);