import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import store from "./redux/store";
import { Provider } from "react-redux";
import NetWorkStatus from './components/network-status';
import ErrorBoundary from "./components/error-boundary";
import DiscoveryPage from './components/pages/discovery-page';
import Authentication from './components/pages/authentication';
import RegistrationPage from './components/pages/registration-page';
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';

const App = () => {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <NetWorkStatus>
          <BrowserRouter>
            <Switch>
              <Route exact path="/:org_id" component={DiscoveryPage} />
              <Route exact path="/event/:event_id" component={Authentication} />
              <Route exact path="/:org_id/:event_id" component={RegistrationPage} />
              <Route exact path="/" component={() => <Redirect to="/juniper" />} />
            </Switch>
          </BrowserRouter>
        </NetWorkStatus>
      </Provider>
    </ErrorBoundary>
  )
}

ReactDOM.render(<App />, document.getElementById('root'));