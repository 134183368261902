import React from 'react';
import styles from './styles.module.css';
import { Link } from "react-router-dom";
import { domain } from '../../../../../config.json';

const ConnectionCard = ({ cover_image, title, org_id, event_id, short_description }) => {
    return (
        <div className={styles.previous_connection_card}>
            <img className={styles.image} alt={title} aria-label={title} src={`${domain}/${cover_image}`} />
            <div className={styles.card_info}>
                <p className={styles.title}>{title}</p>
                <p className={styles.short_description}>
                    {short_description.slice(0, 50)}... <Link className={styles.learn_more_button} to={`${org_id}/${event_id}`}>learn more</Link>
                </p>
            </div>
        </div>
    )
}

export default React.memo(ConnectionCard);