import { useEffect, useState } from 'react';

interface State {
    selected?: string
    devices: MediaDeviceInfo[]

}

interface args {
    state: PermissionStatus["state"]
    type: "videoinput" | "audioinput" | "audiooutput"
}

export interface IUserDevices extends State {
    handleChange: (deviceId: string) => void
}

const useGetUserDevices = ({ type, state }: args): IUserDevices => {
    const [{ devices, selected }, setState] = useState<State>({ devices: [] });

    useEffect(() => {
        if (state === "granted") {
            navigator.mediaDevices.enumerateDevices().then((devices) => {
                devices = devices.filter((device) => (device.deviceId && device.kind === type))
                if (devices.length) {
                    const selected = localStorage.getItem(type)
                    if (selected) return setState({ selected, devices });
                    localStorage.setItem(type, devices[0].deviceId)
                    return setState({ selected: devices[0].deviceId, devices });
                }
            });
        } else {
            setState({ devices: [] })
        }
    }, [type, state]);

    const handleChange = (deviceId: string) => {
        localStorage.setItem(type, deviceId);
        setState((state) => ({ ...state, selected: deviceId }));
    }

    return { devices, selected, handleChange }
}

export default useGetUserDevices;