export const newMessage = ({ data: message }) => {
    return (dispatch) => {
        const { payload, type } = JSON.parse(message)
        switch (type) {
            case "REFRESH": {
                localStorage.clear();
                return window.location.reload();
            }
            default: {
                return dispatch({ type, payload });
            }
        }
    }
}