export default function RaisedHand({ className }: any) {
    return (
        <svg className={className} viewBox="0 0 23 25" fill="none" >
            <path d="M5.73332 13.2208C5.91318 13.8924 5.51526 14.5826 4.84373 14.7632L4.59165 14.8299C3.92082 15.0097 3.23054 14.6118 3.05068 13.9403L0.826371 5.63958C0.64651 4.96805 1.04512 4.27708 1.71665 4.09722L1.96734 4.03055C2.63887 3.85 3.32915 4.24861 3.5097 4.92014L5.73332 13.2208Z" fill="#FFDC5D" />
            <path d="M9.06298 11.8736C9.1734 12.584 8.68659 13.25 7.97687 13.3597L7.80465 13.3868C7.09423 13.4965 6.42826 13.0104 6.31853 12.3L4.69353 1.83471C4.58242 1.1243 5.06923 0.459018 5.77965 0.348601L5.95187 0.321518C6.66228 0.211796 7.32826 0.697907 7.43867 1.40902L9.06298 11.8736Z" fill="#FFDC5D" />
            <path d="M12.3029 11.7924C12.2627 12.4944 11.6606 13.0306 10.9578 12.991L10.7883 12.9813C10.0849 12.9396 9.54877 12.3389 9.58904 11.6361L10.1849 1.28958C10.2252 0.5875 10.8279 0.0499996 11.5307 0.0902774L11.7002 0.100694C12.4029 0.140277 12.9383 0.743055 12.8988 1.44583L12.3029 11.7924Z" fill="#FFDC5D" />
            <path d="M15.4918 13.1528C15.3397 13.8063 14.6883 14.2139 14.0327 14.0625L13.6952 13.984C13.0411 13.8326 12.6341 13.1799 12.7855 12.5257L15.0584 2.71667C15.2105 2.0632 15.8626 1.65487 16.5168 1.80695L16.855 1.88473C17.5091 2.03681 17.9154 2.69028 17.7647 3.34375L15.4918 13.1528Z" fill="#FFDC5D" />
            <path d="M18.0886 18.9993C17.7254 19.6264 16.924 19.8417 16.2963 19.4792L16.1629 19.4021C15.5352 19.0403 15.3206 18.2375 15.6831 17.6104L19.5782 10.8618C19.9414 10.2347 20.7435 10.0188 21.3713 10.3813L21.5046 10.4583C22.1324 10.8208 22.347 11.6236 21.9845 12.2507L18.0886 18.9993Z" fill="#FFDC5D" />
            <path d="M2.29408 11.116C2.62741 12.7431 2.16699 14.7174 2.16699 16.6667C2.16699 18.6167 3.64269 25 10.5003 25C17.358 25 17.792 19.2819 18.8337 17.5931C19.8753 15.9042 18.5774 13.0694 17.5746 14.3264C16.2156 16.0299 15.1677 15.7521 15.7371 12.4583C16.1621 10.0021 13.6788 8.81667 13.2357 11.0333C12.8934 12.7465 12.3337 12.3889 12.4121 10.4951C12.5073 8.21528 9.85727 7.96667 9.72116 10.5583C9.62185 12.4382 8.99269 11.6354 8.8031 10.3681C8.63991 9.28264 5.75449 8.47847 6.0156 10.1465C6.42741 12.775 5.88921 13.5035 5.0031 10.6486C4.44408 8.84722 1.96074 9.48889 2.29408 11.116" fill="#FFDC5D" />
            <path d="M16.0313 15.3146C15.823 15.2299 15.6771 14.9771 15.6153 14.541C14.3021 14.7208 12.5626 15.075 11.1945 16.4125C9.42019 18.1479 9.11742 20.0236 9.1313 21.2785C9.14311 22.2903 9.69519 22.7687 9.857 21.2701C10.0612 19.3771 11.4153 15.6257 16.0313 15.3146" fill="#EF9645" />
        </svg>
    )
}