import { FC, memo } from 'react';
import styles from './styles.module.css';
import moment from 'moment';
import Button from "../../../../button";
import SelfieView from "./selfie-view";
import { RootState } from '../../../../../../index';
import Countdown from "../../../../countdown-banner/countdown";
import { updateEventStatus } from "../../../../../action/event";
import { useDispatch, shallowEqual, useSelector } from 'react-redux';

const WaitingRoom: FC = () => {
    const dispatch = useDispatch()
    const { type, event, loading } = useSelector(({ user, event }: RootState) => {
        return {
            type: user.type,
            event: event?.event,
            error: event?.event?.error,
            loading: event?.event?.loading,
        }
    }, shallowEqual);

    const handleStartEvent = () => {
        dispatch(updateEventStatus({ is_started: true }))
    }
    if (event) {
        return (
            <div className={`${styles.waiting_room} ${(type === "moderator") && styles.show_button}`}>
                <div className={styles.countdown}>
                    <p className={styles.event_title}>{event.title}</p>
                    <p className={styles.date}>{moment(event.date).local().format("LLLL")}</p>
                    <p className={styles.sub_title}>Live event starts in:</p>
                    <Countdown className={styles.countdown_clock} date={event.date} />
                </div>
                {(type === "moderator") && <Button className={styles.start_event} onClick={handleStartEvent} disabled={loading} loading={loading}>
                    Start the event
                </Button>}
                <SelfieView />
            </div>
        )
    } else {
        return (
            <div className={styles.waiting_room}>
                Event dose not exist
            </div>
        )
    }

}

export default memo(WaitingRoom);