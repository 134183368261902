import { memo, FC } from 'react';
import styles from './styles.module.css';
import { domain } from '../../../../../../config.json';


interface Props {
    key: any
    profile: string
    description: string
}

const Guest: FC<Props> = ({ profile, description }) => {
    return (
        <div className={styles.guest}>
            <img className={styles.image} src={`${domain}/${profile}`} alt="" />
            <p className={styles.info}>{description}</p>
        </div>
    )
}

export default memo(Guest);