import { FC, memo } from 'react';
import styles from './styles.module.css';
import Modal from '../../../../modal';
import Icon from '../../../../icon';

interface Props {
    title: string
    description: string
    onClose: () => void
}

const EventOverview: FC<Props> = ({ onClose, title, description }) => {
    return (
        <Modal className={styles.event_overview}>
            <p className={styles.header}>{title}</p>
            <p className={styles.details}>{description}</p>
            <button className={styles.close_button} onClick={onClose}>
                <Icon className={styles.close_icon} icon="close" />
            </button>
        </Modal>
    )
}

export default memo(EventOverview);