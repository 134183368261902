import { AxiosError } from 'axios';

export const LOWERED_HAND = "LOWER-HAND";
export const RAISED_HAND = "RAISE-HAND";
export const LOWERED_HAND_FULFILLED = "LOWER-HAND-FULFILLED";
export const RAISED_HAND_FULFILLED = "RAISE-HAND-FULFILLED";
export const FETCH_PROFILE_FULFILLED = "FETCH-PROFILE-FULFILLED";
export const FETCH_PROFILE_REJECTED = "FETCH-PROFILE-REJECTED";
export const FETCH_PROFILE_PENDING = "FETCH-PROFILE-PENDING";
export const FETCH_PROFILE = "FETCH-PROFILE";
export const LOG_OUT = "LOG-OUT";

export interface DispatchUserAction {
    type: typeof FETCH_PROFILE
    payload: Promise<void>
}

export interface FetchProfileLoading {
    type: typeof FETCH_PROFILE_PENDING
}

export interface FetchProfileRejected {
    type: typeof FETCH_PROFILE_REJECTED
    payload: AxiosError | Error
}

export interface FetchProfileFulfilled {
    type: typeof FETCH_PROFILE_FULFILLED
    payload: { data: UserProfile }
}

export interface LogOut {
    type: typeof LOG_OUT
}

export type UserProfile = {
    user_id?: string
    profile?: string
    last_name?: string
    first_name?: string
    raised_hand?: boolean
    type?: "guest" | "participant" | "moderator"
}

export interface IUser extends UserProfile {
    loading: boolean
    logged_in: boolean
    error?: string
}

export interface DispatchLoweredHand {
    type: typeof LOWERED_HAND
    payload: Promise<void>
}


export interface DispatchRaisedHand {
    type: typeof RAISED_HAND
    payload: Promise<void>
}

export interface RaisedHand {
    type: typeof RAISED_HAND_FULFILLED
    payload: string
}

export interface LoweredHand {
    type: typeof LOWERED_HAND_FULFILLED
    payload: string
}

export type IUserAction = FetchProfileLoading | FetchProfileRejected | FetchProfileFulfilled | LogOut | LoweredHand | RaisedHand