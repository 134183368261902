import { memo, FC } from 'react';
import styles from './styles.module.css';
import VideoPlayer from '../video-player';

interface Props {
    deck: string
    video: string
    heading: string
    thumbnail: string
    description: string
}

const VideoHighlight: FC<Props> = ({ deck, heading, description, thumbnail, video }) => {
    return (
        <div className={styles.video_highlight}>
            <VideoPlayer className={styles.video} color="white" src={video} thumbnail={thumbnail} />
            <div className={styles.description}>
                <p className={styles.deck}>{deck}</p>
                <p className={styles.title}>{heading}</p>
                <p className={styles.body}>{description}</p>
            </div>
        </div>
    )
}

export default memo(VideoHighlight);