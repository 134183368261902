import { memo, FC } from 'react';
import styles from './styles.module.css';

interface Props {
    color: string
    className?: string
    onClick: () => void
}

const PlayButton: FC<Props> = ({ className, onClick, color }) => {
    return <div className={`${className} ${styles.play_button} ${styles[color]}`} onClick={onClick} />
}

export default memo(PlayButton);