import { FC, memo } from 'react';
import styles from './styles.module.css';
import Icons from '../../../../../../icon';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    icon: string
}

const Button: FC<Props> = ({ icon, className, children, ...rest }) => {
    return (
        <button className={`${styles.button} ${className}`} {...rest}>
            {children}
            <Icons className={styles.icon} icon={icon} />
        </button>
    )
}

export default memo(Button);