import { AxiosError } from 'axios'

export const FETCH_EVENT_FULFILLED = "FETCH-EVENT-FULFILLED";
export const FETCH_EVENT_REJECTED = "FETCH-EVENT-REJECTED";
export const FETCH_EVENT_PENDING = "FETCH-EVENT-PENDING";
export const FETCH_EVENT = "FETCH-EVENT";

export const UPDATE_EVENT_STATUS_FULFILLED = "UPDATE-EVENT-STATUS-FULFILLED";
export const UPDATE_EVENT_STATUS_REJECTED = "UPDATE-EVENT-STATUS-REJECTED";
export const UPDATE_EVENT_STATUS_PENDING = "UPDATE-EVENT-STATUS-PENDING";
export const UPDATE_EVENT_STATUS = "UPDATE-EVENT-STATUS";

export const UPDATE_BREAKOUT_ROOM_FULFILLED = "UPDATE-BREAKOUT-ROOM-FULFILLED";
export const UPDATE_BREAKOUT_ROOM_REJECTED = "UPDATE-BREAKOUT-ROOM-REJECTED";
export const UPDATE_BREAKOUT_ROOM_PENDING = "UPDATE-BREAKOUT-ROOM-PENDING";
export const UPDATE_BREAKOUT_ROOM = "UPDATE-BREAKOUT-ROOM";

export const DELETE_BREAKOUT_ROOM_FULFILLED = "DELETE-BREAKOUT-ROOM-FULFILLED";
export const DELETE_BREAKOUT_ROOM_REJECTED = "DELETE-BREAKOUT-ROOM-REJECTED";
export const DELETE_BREAKOUT_ROOM_PENDING = "DELETE-BREAKOUT-ROOM-PENDING";
export const DELETE_BREAKOUT_ROOM = "DELETE-BREAKOUT-ROOM";

export const EVENT_STATUS_UPDATED = "EVENT-STATUS-UPDATED";
export const BREAKOUT_ROOM_CREATED = "BREAKOUT-ROOM-CREATED";
export const BREAKOUT_ROOM_DELETED = "BREAKOUT-ROOM-DELETED";

export interface Guest {
    name: string
    title: string
    description: string
    profile: string
}

export interface BreakoutRoom {
    panelists: boolean
    moderator: boolean
    rooms: number
}

export interface EventStatus {
    is_started?: boolean
    is_previous_event?: boolean
    is_message_enabled?: boolean
    is_registration_open?: boolean
}

export interface SocialMedia {
    name: string
    url: string
}

export interface Event extends EventStatus {
    org_name: string
    cover_image: string
    title: string
    description: string
    learn_more: string
    guests: Guest[]
    social_medias: SocialMedia[]
    shared_text: string
    org_id: string
    event_id: string
    date: number
    favicon: string
    logo: string
    loading: boolean
    error: string
    breakout_room?: BreakoutRoom
}

export interface IEvent {
    loading: boolean
    error?: string
    event?: Event
}

export interface DispatchFetchEvent {
    type: typeof FETCH_EVENT
    payload: Promise<any>
}

export interface DispatchUpdateEventStatus {
    type: typeof UPDATE_EVENT_STATUS
    payload: Promise<any>
}

export interface DispatchUpdateBreakoutRoom {
    type: typeof UPDATE_BREAKOUT_ROOM
    payload: Promise<any>
}

export interface DispatchDeleteBreakoutRoom {
    type: typeof DELETE_BREAKOUT_ROOM
    payload: Promise<any>
}

export interface FetchEventPending {
    type: typeof FETCH_EVENT_PENDING
}

export interface FetchEventRejected {
    type: typeof FETCH_EVENT_REJECTED
    payload: AxiosError
}

export interface FetchEventFulfilled {
    type: typeof FETCH_EVENT_FULFILLED
    payload: {
        data: Event
    }
}

export interface UpdateEventStatusPending {
    type: typeof UPDATE_EVENT_STATUS_PENDING
}

export interface UpdateEventStatusRejected {
    type: typeof UPDATE_EVENT_STATUS_REJECTED
    payload: AxiosError
}

export interface UpdateEventStatusFulfilled {
    type: typeof UPDATE_EVENT_STATUS_FULFILLED
    payload: {
        data: EventStatus
    }
}

export interface UpdateBreakoutRoomPending {
    type: typeof UPDATE_BREAKOUT_ROOM_PENDING
}

export interface UpdateBreakoutRoomRejected {
    type: typeof UPDATE_BREAKOUT_ROOM_REJECTED
    payload: AxiosError
}

export interface UpdateBreakoutRoomFulfilled {
    type: typeof UPDATE_BREAKOUT_ROOM_FULFILLED
    payload: {
        data: BreakoutRoom
    }
}

export interface DeleteBreakoutRoomPending {
    type: typeof DELETE_BREAKOUT_ROOM_PENDING
}

export interface DeleteBreakoutRoomRejected {
    type: typeof DELETE_BREAKOUT_ROOM_REJECTED
    payload: AxiosError
}

export interface DeleteBreakoutRoomFulfilled {
    type: typeof DELETE_BREAKOUT_ROOM_FULFILLED
}

export interface BreakoutRoomCreated {
    type: typeof BREAKOUT_ROOM_CREATED
    payload: boolean
}

export interface BreakoutRoomDeleted {
    type: typeof BREAKOUT_ROOM_DELETED
    payload: boolean
}

export interface EventStatusUpdated {
    type: typeof EVENT_STATUS_UPDATED
    payload: EventStatus
}

export type IEventActions = FetchEventPending | FetchEventRejected | FetchEventFulfilled | UpdateEventStatusPending | UpdateEventStatusRejected | UpdateEventStatusFulfilled | UpdateBreakoutRoomPending | UpdateBreakoutRoomRejected | UpdateBreakoutRoomFulfilled | DeleteBreakoutRoomPending | DeleteBreakoutRoomRejected | DeleteBreakoutRoomFulfilled | BreakoutRoomDeleted | BreakoutRoomCreated | EventStatusUpdated