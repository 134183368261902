import { FC, memo, useEffect, useState } from 'react';
import styles from './styles.module.css';
import Input from "./input";
import Icon from "../../../icon";
import Messages from "./messages";
import Notification from "./notifications";

const ParticipantChat: FC = () => {
    const [show, toggle] = useState(true);

    useEffect(() => { setTimeout(() => toggle(false), 3000) }, []);

    return (
        <div className={styles.participant_chat}>
            <div className={`${styles.container} ${!show && styles.hide}`}>
                <button className={styles.toggle} onClick={() => toggle(!show)}>
                    <Icon className={styles.icon} icon="arrow" />
                </button>
                <div className={styles.chat}>
                    <Messages />
                    <Input />
                </div>
            </div>
            {!show && <Notification show={() => toggle(true)} />}
        </div>
    )
}

export default memo(ParticipantChat);