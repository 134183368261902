import thunk from 'redux-thunk';
import reducer from "../reducers";
import { Dispatch } from 'react';
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';

const createPromise = () => (next: Dispatch<any>) => (action: any) => {
    if (action.payload instanceof Promise) {
        next({ type: action.type.concat("-PENDING") })
        action.payload.then((res: any) => {
            return next({ type: action.type.concat("-FULFILLED"), payload: res })
        }).catch((error: any) => {
            return next({ type: action.type.concat("-REJECTED"), payload: error })
        })
    } else {
        return next(action)
    }
}

const middleware = applyMiddleware(createPromise, thunk);
const DevTools = composeWithDevTools(middleware);
const store = createStore(reducer, DevTools);

export default store;