import { FC, memo } from 'react';
import styles from './styles.module.css';
import Participant from '../participant';
import { useTransition } from 'react-spring';
import { RootState } from '../../../../../../../index';
import { shallowEqual, useSelector } from 'react-redux';

const BreakoutRoom: FC = () => {
    const { participants, size } = useSelector(({ meeting }: RootState) => {
        let row = 1, column = 1;
        const { moderator, guests, participants } = meeting;
        const users = [...guests, ...participants];
        if (moderator) users.push(moderator);

        while (users.length > (column * row)) {
            if (column === row) column++
            else row++
        }
        return {
            participants: users,
            size: { width: `${95 / column}%`, height: `${93.5 / row}%` }
        }
    }, shallowEqual);

    const transitions = useTransition(participants, {
        keys: (item) => item.sid,
        enter: size,
        update: size,
        initial: { ...size },
        from: { width: '0%', height: '0%' }
    });

    return (
        <div className={styles.breakout_room}>
            {transitions((style, p) => <Participant key={p.sid} {...p} style={style} />)}
        </div>
    )
}

export default memo(BreakoutRoom);