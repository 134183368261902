import { IUser, IUserAction, LOG_OUT } from './types';
import { FETCH_PROFILE_FULFILLED, FETCH_PROFILE_REJECTED, FETCH_PROFILE_PENDING } from './types';
import { RAISED_HAND_FULFILLED, LOWERED_HAND_FULFILLED } from './types';

const InitialState = { loading: true, logged_in: false }

const user = (state: IUser = InitialState, action: IUserAction): IUser => {
    switch (action.type) {
        case LOG_OUT: {
            return Object.assign({}, state, { loading: false, logged_in: false });
        }
        case FETCH_PROFILE_PENDING: {
            return Object.assign({}, state, { loading: true, logged_in: false });
        }
        case FETCH_PROFILE_FULFILLED: {
            const profile = action.payload.data
            return Object.assign({}, profile, { loading: false, logged_in: true });
        }
        case FETCH_PROFILE_REJECTED: {
            return Object.assign({}, state, { loading: false, logged_in: false });
        }
        case RAISED_HAND_FULFILLED: {
            return Object.assign({}, state, { raised_hand: true })
        }
        case LOWERED_HAND_FULFILLED: {
            return Object.assign({}, state, { raised_hand: false })
        }
        default: {
            return state
        }
    }
}

export default user;