import Modal, { Text, Title } from '../modal';
import Ripple from '../loading-animation/ripple';
import { useEffect, useState, memo, Fragment } from 'react';

const NetworkStatus = ({ children }: any) => {
    const [online, setStates] = useState(navigator.onLine)

    useEffect(() => {
        const updateNetworkStatue = () => {
            setStates(navigator.onLine)
        }
        window.addEventListener('online', updateNetworkStatue);
        window.addEventListener('offline', updateNetworkStatue);
        return () => {
            window.removeEventListener('online', updateNetworkStatue);
            window.removeEventListener('offline', updateNetworkStatue);
        }
    }, []);

    return (
        <Fragment>
            {!online && <Modal>
                <Title>Having trouble connecting!</Title>
                <Text>Please check your internet connection.</Text>
                <Ripple />
            </Modal>}
            {children}
        </Fragment>
    )
}

export default memo(NetworkStatus);
