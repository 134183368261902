import { FC, memo } from 'react';
import styles from './styles.module.css';
import GalleryView from './gallery-view';
import SpotlightView from './spotlight-view';
import { RootState } from '../../../../../../../index';
import { shallowEqual, useSelector } from 'react-redux';
import RaiseHandNotification from './raise-hand-notification';

const MeetingRoom: FC = () => {
    const { guests } = useSelector(({ meeting }: RootState) => {
        const { moderator, total_guests } = meeting;
        if (moderator && total_guests < 4) return { guests: (total_guests + 1) }
        return { guests: total_guests };
    }, shallowEqual);

    return (
        <div className={`${styles.meeting_room} ${styles[`room_${guests}`]}`}>
            <SpotlightView />
            <GalleryView />
            <RaiseHandNotification />
        </div>
    )

}

export default memo(MeetingRoom);