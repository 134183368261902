import usePermission from './use-permission';
import useGetUserDevices, { IUserDevices } from './use-get-user-devices';
import { useState, useEffect } from 'react';
import { createLocalAudioTrack, LocalAudioTrack } from 'twilio-video';

interface AudioTrack extends IUserDevices {
    handleTrack: () => void
    track: LocalAudioTrack | null
    state: PermissionStatus["state"]
}

const useLocalAudioTrack = (): AudioTrack => {
    const [state] = usePermission("microphone");
    const [create, setCreate] = useState<boolean>(true);
    const [track, setTrack] = useState<LocalAudioTrack | null>(null);
    const { devices, selected, handleChange } = useGetUserDevices({ type: "audioinput", state });

    useEffect(() => {
        if (create && state !== "denied") {
            const constrains = { deviceId: {}, echoCancellation: true }
            if (selected) constrains.deviceId = { exact: selected }
            createLocalAudioTrack(constrains).then((track) => {
                setTrack((prevTrack) => {
                    if (prevTrack) prevTrack.stop();
                    return track;
                });
            }).catch((error) => console.error(error.message));
            return () => {
                setTrack((prevTrack) => {
                    if (prevTrack) prevTrack.stop();
                    return null;
                });
            }
        }
    }, [create, state, selected]);

    const handleTrack = () => setCreate(!create);

    return { track, state, devices, selected, handleTrack, handleChange }
}

export default useLocalAudioTrack;