import { FC, memo, useEffect, useState } from 'react';
import styles from './styles.module.css';
import Icon from '../../../../../../../icon';
import Modal from '../../../../../../../modal';
import Button from '../../../../../../../button';
import { RootState } from '../../../../../../../../../index';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { updateBreakoutRoom, deleteBreakoutRoom } from "../../../../../../../../action/event";

interface Props {
    close: () => void
}

const BreakoutRoomsModal: FC<Props> = ({ close }) => {
    const dispatch = useDispatch();
    const [values, setValues] = useState({ rooms: 2, panelists: false, moderator: false });
    const { error, loading, breakout_room, total_participants } = useSelector(({ event, meeting: { room } }: RootState) => {
        const total = room?.participants.size || 0
        return {
            error: event?.event?.error,
            loading: event?.event?.loading,
            total_participants: total + 1,
            breakout_room: event?.event?.breakout_room,
        }
    }, shallowEqual);


    useEffect(() => {
        if (breakout_room) setValues(breakout_room);
    }, [breakout_room]);

    const handleChange = ({ target: { name, value, type, checked } }: React.ChangeEvent<HTMLInputElement>) => {
        if (type === "checkbox") {
            setValues((prevState) => ({ ...prevState, [name]: checked }));
        } else {
            setValues((prevState) => ({ ...prevState, [name]: value }));
        }
    }

    const handleCreateBreakoutRooms = () => {
        dispatch(updateBreakoutRoom(values));
    }

    const handleCloseBreakoutRooms = () => {
        dispatch(deleteBreakoutRoom());
    }

    return (
        <Modal className={styles.modal}>
            <button className={styles.close_modal} onClick={close}>
                <Icon className={styles.close_icon} icon="close" />
            </button>
            <p className={styles.total_participants}>
                Total <b>{total_participants}</b> participants in the room
            </p>
            <div className={styles.item}>
                <label>Number of rooms</label>
                <input type="number" name="rooms" value={values.rooms} min={2} onChange={handleChange} disabled={breakout_room ? true : false} />
            </div>
            <div className={styles.item}>
                <label>Include moderator</label>
                <input type="checkbox" name="moderator" checked={values.moderator} onChange={handleChange} disabled={breakout_room ? true : false} />
            </div>
            <div className={styles.item}>
                <label>Include panelists</label>
                <input type="checkbox" name="panelists" checked={values.panelists} onChange={handleChange} disabled={breakout_room ? true : false} />
            </div>
            {breakout_room && <Button className={styles.button} style={{ background: "#C0372A" }} onClick={handleCloseBreakoutRooms} loading={loading} disabled={loading}>
                Close Breakout Rooms
                </Button>}
            {!breakout_room && <Button className={styles.button} onClick={handleCreateBreakoutRooms} loading={loading} disabled={loading}>
                Create Breakout Rooms
                </Button>}
            {error && <p className={styles.error_message}>{error}</p>}
        </Modal>
    )
}

export default memo(BreakoutRoomsModal);