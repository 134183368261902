import styles from './styles.module.css';
import { memo } from 'react';

const Modal = ({ children, className, style }: any) => {
    return (
        <div className={`${styles.overlay} ${className}`}>
            <div className={styles.modal} style={style}>
                {children}
            </div>
        </div>
    )
}

export const Title = ({ className, children, style }: any) => {
    return (
        <p className={`${className} ${styles.title}`} style={style}>{children}</p>
    )
}

export const Text = ({ className, children, style }: any) => {
    return (
        <p className={`${className} ${styles.text}`} style={style}>{children}</p>
    )
}

export const Button = ({ className, red, children, style, ...rest }: any) => {
    return (
        <button className={`${className} ${styles.button} ${red && styles.red}`} {...rest} style={style}>{children}</button>
    )
}

export default memo(Modal);
