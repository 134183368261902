import { FC, memo } from 'react';
import styles from './styles.module.css';
import Share from './share';
import TawkTo from './tawk-to';

interface Props { }

const Footer: FC<Props> = () => {
    return (
        <div className={styles.footer}>
            <Share />
            <TawkTo />
        </div>
    )
}

export default memo(Footer);