import { memo } from 'react';
import styles from './styles.module.css';
import RaiseHandItem from './raise-hand-item';
import { useSelector, shallowEqual } from 'react-redux';

const RaiseHandNotification = () => {
    const raisedHands = useSelector(({ raised_hands }) => raised_hands, shallowEqual);

    return (
        <div className={styles.raise_hand_notification}>
            {raisedHands.map((raised_hand, i) => <RaiseHandItem key={i} {...raised_hand} />)}
        </div>
    );
};

export default memo(RaiseHandNotification);