import { memo, FC, useState, useRef } from 'react';
import styles from './styles.module.css';
import PlayButton from './play-button';
import { domain } from '../../../../config.json';


interface Props {
    src: string
    color: string
    thumbnail: string
    className?: string
}

interface HTMLMediaElement extends HTMLVideoElement {
    controlsList: string
    disablePictureInPicture: boolean
}

const VideoPlayer: FC<Props> = ({ className, src, thumbnail, color }) => {
    const [playing, setPlaying] = useState(false);
    const video = useRef<HTMLMediaElement | null>(null);

    const handlePlay = () => {
        setTimeout(() => {
            if (video.current) {
                video.current.disablePictureInPicture = true
                video.current.controlsList = "nodownload"
                video.current.play()
            }
        }, 500)
    }
    return (
        <div className={`${className} ${styles.video_player}`}>
            {!playing && <img className={styles.thumbnail} src={`${domain}/${thumbnail}`} alt="" />}
            <video
                className={styles.video}
                ref={video}
                src={`${domain}/${src}`}
                autoPlay={false}
                controls={playing}
                controlsList=""
                onPause={() => setPlaying(false)}
                onPlaying={() => setPlaying(true)}
            />
            {!playing && <PlayButton className={styles.play_button} onClick={handlePlay} color={color} />}
        </div>
    )
}

export default memo(VideoPlayer);