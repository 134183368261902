import { useState, useEffect } from 'react';

type state = "granted" | "prompt" | "denied"

const usePermission = (name: "camera" | "microphone") => {
    const [state, setState] = useState<state>("denied");

    useEffect(() => {
        navigator.permissions.query({ name }).then((result) => {
            setState(result.state);
            result.addEventListener("change", () => setState(result.state))
        });
    }, [name]);

    return [state];
}

export default usePermission;