import { FC, memo } from 'react';
import styles from './styles.module.css';
import Participant from '../../participant';
import { useTransition } from 'react-spring';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../index';

const SpotlightView: FC = () => {
    const { users, guests } = useSelector(({ meeting }: RootState) => {
        let users = meeting.guests;
        let guests = meeting.total_guests;
        const moderator = meeting.moderator;

        if (guests < 4 && moderator) {
            guests += 1;
            return { users: [moderator, ...users], guests };
        } else {
            return { users, guests };
        }
    }, shallowEqual);

    const transitions = useTransition(users, {
        keys: (p) => p.sid,
        from: { scale: 0 },
        enter: { scale: 1 },
        initial: { scale: 1 },
    });

    return (
        <div className={`${styles.spotlight_view} ${styles[`spotlight_view_${guests}`]}`}>
            {transitions((style, p) => <Participant key={p.sid} {...p} style={style} />)}
        </div>
    )
}

export default memo(SpotlightView);