import { FC, Fragment, memo, useEffect } from 'react';
import styles from './styles.module.css';
import Header from '../../header';
import Footer from '../../footer';
import Loading from '../../loading';
import LoginFrom from './login-form';
import { Helmet } from "react-helmet";
import AboutEvent from '../../about-event';
import { useParams } from "react-router-dom";
import CountdownBanner from '../../countdown-banner';
import { RootState } from "../../../../index";
import { fetchEvent } from "../../../action/event";
import { useDispatch, shallowEqual, useSelector } from 'react-redux';


const LoginPage: FC = () => {
    const dispatch = useDispatch();
    const { event_id } = useParams<any>();
    const { event, loading, error } = useSelector(({ event }: RootState) => {
        return {
            error: event.error,
            event: event.event,
            loading: event.loading,
        }
    }, shallowEqual);

    useEffect(() => { dispatch(fetchEvent(event_id)) }, [event_id, dispatch]);

    if (loading) {
        return <Loading />
    } else if (error) {
        return <Loading message={error} stopLoading />
    } else if (event) {
        return (
            <Fragment>
                <Helmet>
                    <link rel="icon" href={event.favicon} />
                    <title>{event.title}</title>
                </Helmet>
                <Header logo={event.logo} orgId={event.org_id} />
                <div className={styles.login_page}>
                    <CountdownBanner
                        date={event.date}
                        title={event.title}
                        coverImage={event.cover_image}
                        showCountdown={!event.is_previous_event}
                    />
                    <div className={styles.body}>
                        <AboutEvent about={event.description} guests={event.guests} />
                        {!event.is_previous_event && <LoginFrom orgName={event.org_name} />}
                    </div>
                </div>
                <Footer logo={event.logo} orgId={event.org_id} socialMedias={event.social_medias} />
            </Fragment>
        )
    } else {
        return <p>404 Not Found</p>
    }
}

export default memo(LoginPage);