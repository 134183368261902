import { FC, memo } from 'react';
import styles from './styles.module.css';
import Countdown from './countdown';
import moment from 'moment';
import { domain } from '../../config.json';

interface Props {
    date: number
    title: string
    coverImage: string
    showCountdown: boolean
}

const CountdownBanner: FC<Props> = ({ date, title, coverImage, showCountdown }) => {
    return (
        <div className={styles.countdown_banner}>
            <div className={styles.countdown}>
                <p className={styles.event_title}>{title}</p>
                <p className={styles.date}>{date ? moment(date).local().format("LLLL") : "TBD"}</p>
                {(showCountdown && date) && <p className={styles.sub_title}>Live event starts in:</p>}
                {(showCountdown && date) && <Countdown date={date} />}
            </div>
            <img className={styles.banner_image} src={`${domain}/${coverImage}`} alt={title} />
        </div>
    )
}

export default memo(CountdownBanner);