import { FC, memo, useEffect, useRef } from 'react';
import Message from './message';
import styles from './styles.module.css';
import { Title, Text } from "../../../../modal";
import { RootState } from "../../../../../../index";
import waveHand from "../../../../../images/wave-hand.png";
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { fetchMessages, resendMessage } from "../../../../../action/message";
import { IMessage } from "../../../../../redux/reducers/messages-reducer/types";

const Messages: FC = () => {
    const dispatch = useDispatch();
    const lastItem = useRef<HTMLDivElement | null>(null);
    const messageScrollRef = useRef<HTMLDivElement | null>(null);
    const { page, loading, messages, user_id } = useSelector(({ user, messages }: RootState) => {
        return {
            page: messages.page,
            user_id: user.user_id,
            loading: messages.loading,
            messages: messages.messages,
        }
    }, shallowEqual);

    useEffect(() => {
        if (lastItem.current && page && !loading) {
            const observer = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting) {
                    dispatch(fetchMessages(page))
                }
            });
            observer.observe(lastItem.current);
            return () => observer.disconnect();
        }
    }, [lastItem, page, loading, dispatch]);


    useEffect(() => {
        dispatch(fetchMessages())
    }, [dispatch]);

    const handleResendMessage = (message: IMessage) => {
        dispatch(resendMessage(message))
    }

    if (messages.length) {
        return (
            <div className={styles.messages} ref={messageScrollRef}>
                {messages.map((m, i) => {
                    if ((messages.length - 2) === i) {
                        return <Message key={m.time_stamp} reference={lastItem} isUser={(m.user_id === user_id)} {...m} resendMessage={() => handleResendMessage(m)} />
                    } else {
                        return <Message key={m.time_stamp} isUser={(m.user_id === user_id)} {...m} resendMessage={() => handleResendMessage(m)} />
                    }
                })}
            </div>
        )
    } else {
        return (
            <div className={styles.no_messages}>
                <img className={styles.wave_hand} src={waveHand} alt="celebrate" />
                <Title style={{ color: "#686262", fontSize: "16px", fontWeight: 700, lineHeight: "23px" }}>
                    There are no conversations yet.
                </Title>
                <Text style={{ color: "#686262", fontSize: "16px", fontWeight: 400, lineHeight: "23px" }}>
                    Start by saying hello to others!
                </Text>
            </div>
        )
    }

}

export default memo(Messages);