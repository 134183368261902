import { FC, memo, useEffect } from 'react';
import styles from './styles.module.css';
import { Helmet } from "react-helmet";
import Loading from '../../loading';
import Footer from './footer';
import Header from './header';
import VideoFeed from './video-feed';
import SSE from './server-sent-events';
import ParticipantChat from './participant-chat';
import { useParams } from "react-router-dom";
import { RootState } from "../../../../index";
import { fetchEvent } from "../../../action/event";
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { LOG_OUT } from '../../../redux/reducers/user-reducer/types';


const EventPage: FC = () => {
    const dispatch = useDispatch();
    const { event_id } = useParams<any>();
    const { event, is_previous_event, loading, error } = useSelector(({ event }: RootState) => {
        return {
            event: event.event,
            error: event.error,
            loading: event.loading,
            is_previous_event: event?.event?.is_previous_event,
        }
    }, shallowEqual);

    useEffect(() => {
        if (is_previous_event) {
            dispatch({ type: LOG_OUT })
        } else {
            dispatch(fetchEvent(event_id))
        }
    }, [event_id, is_previous_event, dispatch]);

    if (loading) {
        return <Loading />
    } else if (!loading && error) {
        return <Loading message={error} stopLoading />
    } else if (event) {
        return (
            <SSE>
                <div className={styles.event_page}>
                    <Helmet>
                        <link rel="icon" href={event.favicon} />
                        <title>{event?.title}</title>
                        <meta name="title" property='og:title' content={event?.title} />
                        <meta name="description" property="og:description" content={event?.description} />
                        <meta name="image" property='og:image' content={event?.cover_image} />
                    </Helmet>
                    <Header logo={event.logo} title={event.title} description={event.description} guests={event.guests} learnMore={event.learn_more || ""} />
                    <VideoFeed />
                    <ParticipantChat />
                    <Footer />
                </div>
            </SSE>
        )
    }
    return null;
}

export default memo(EventPage);