import { FC, memo, useState, useEffect, Fragment } from 'react';
import { Room, RemoteTrack, RemoteAudioTrack } from 'twilio-video';
import UserInteractionRequired from './user-interaction-required-modal';
const PlayAudioTracks: FC<{ room: Room }> = ({ room }) => {
    const [needInteraction, toggle] = useState(false);

    useEffect(() => {
        if (!needInteraction) {
            const createAudioElement = (track: RemoteAudioTrack) => {
                const elem = track.attach();
                elem.volume = 0;
                elem.muted = true;
                elem.autoplay = false;
                elem.controls = false;
                const handlePlayError = (error: MediaError) => {
                    // The play() request was interrupted by a call to pause();
                    const msg = "play() failed because the user didn't interact with the document first"
                    if (error.message.includes(msg)) {
                        console.error("user didn't interact with the document first");
                        toggle(true);
                    } else {
                        elem.play().then(handlePlay).catch(handlePlayError);
                    }
                }


                const handlePlay = () => {
                    elem.volume = 1;
                    elem.muted = false;
                }
                if (elem.paused) elem.play().then(handlePlay).catch(handlePlayError);
                elem.addEventListener("pause", handlePlay);
            }
            room.participants.forEach((p) => {
                p.audioTracks.forEach((publication) => {
                    const track = publication.track;
                    if (track && track.kind === "audio") createAudioElement(track);
                })
            });
            room.on('trackSubscribed', (track: RemoteTrack) => {
                if (track && track.kind === "audio") createAudioElement(track);
            });
            room.on('trackUnsubscribed', (track: RemoteTrack) => {
                if (track && track.kind === "audio") {
                    track.detach().forEach((tag) => tag.remove());
                }
            });
            return () => {
                room.participants.forEach((p) => {
                    p.audioTracks.forEach((publication) => {
                        const track = publication.track;
                        if (track && track.kind === "audio") {
                            track.detach().forEach((tag) => tag.remove());
                        }
                    })
                });
            }
        }
    }, [room, needInteraction]);

    return (
        <Fragment>
            {needInteraction && <UserInteractionRequired close={() => toggle(false)} />}
        </Fragment>
    )
}

export default memo(PlayAudioTracks);