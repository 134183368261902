import axios from '../axios';
import { Dispatch } from 'redux';
import { FETCH_PROFILE, RAISED_HAND, LOWERED_HAND } from '../redux/reducers/user-reducer/types';
import { DispatchRaisedHand, DispatchLoweredHand, DispatchUserAction } from '../redux/reducers/user-reducer/types';

export const fetchProfile = (token: string) => {
    axios.defaults.headers['authorization'] = token;
    return (dispatch: Dispatch<DispatchUserAction>) => {
        dispatch({ type: FETCH_PROFILE, payload: axios.get('users/profile') })
    }
}

export const RaiseHand = () => {
    return (dispatch: Dispatch<DispatchRaisedHand>) => {
        dispatch({ type: RAISED_HAND, payload: axios.post('rooms/raise-hand') });
    }
}

export const LowerHand = () => {
    return (dispatch: Dispatch<DispatchLoweredHand>) => {
        dispatch({ type: LOWERED_HAND, payload: axios.post('rooms/lower-hand') });
    }
}