import { IMessage, IActions, SEND_MESSAGE_PENDING, SEND_MESSAGE_REJECTED } from './types';
import { RESEND_MESSAGE_PENDING, SEND_MESSAGE_FULFILLED, NEW_MESSAGE } from './types';
import { FETCH_MESSAGES_FULFILLED, FETCH_MESSAGES_PENDING, FETCH_MESSAGES_REJECTED } from './types';

interface IMessages {
    page?: string
    loading?: boolean
    messages: IMessage[]
}

const initState = { messages: [] }

const messages = (state: IMessages = initState, action: IActions): IMessages => {
    switch (action.type) {
        case FETCH_MESSAGES_PENDING: {
            return Object.assign({}, state, { loading: true });
        }
        case FETCH_MESSAGES_REJECTED: {
            return Object.assign({}, state, { loading: false });
        }
        case FETCH_MESSAGES_FULFILLED: {
            // return action.payload.data;
            const { messages, page } = action.payload.data;
            return Object.assign({}, state, {
                page,
                loading: false,
                messages: [...state.messages, ...messages]
            });
        }
        case NEW_MESSAGE: {
            return Object.assign({}, state, {
                messages: [action.payload, ...state.messages]
            });
        }
        case SEND_MESSAGE_PENDING: {
            const message = action.payload
            message.status = "pending"
            return Object.assign({}, state, {
                messages: [message, ...state.messages]
            });
        }
        case RESEND_MESSAGE_PENDING: {
            const { time_stamp } = action.payload
            return Object.assign({}, state, {
                messages: state.messages.map((message) => {
                    if (message.time_stamp === time_stamp) {
                        message.status = "pending"
                    }
                    return message
                })
            });
        }
        case SEND_MESSAGE_FULFILLED: {
            const message = action.payload.data
            return Object.assign({}, state, {
                messages: state.messages.map((ms) => {
                    if (ms.time_stamp !== message.time_stamp) return ms
                    return message
                })
            });
        }
        case SEND_MESSAGE_REJECTED: {
            return Object.assign({}, state, {
                messages: state.messages.map((message) => {
                    if (message.time_stamp === action.payload) {
                        message.status = "rejected"
                    }
                    return message
                })
            });
        }
        default: {
            return state
        }
    }
}

export default messages;