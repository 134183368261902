import { BREAKOUT_ROOM_CREATED, BREAKOUT_ROOM_DELETED, IEvent, IEventActions } from './types';
import { FETCH_EVENT_FULFILLED, FETCH_EVENT_PENDING, FETCH_EVENT_REJECTED } from './types';
import { UPDATE_BREAKOUT_ROOM_FULFILLED, UPDATE_BREAKOUT_ROOM_PENDING, UPDATE_BREAKOUT_ROOM_REJECTED } from './types';
import { DELETE_BREAKOUT_ROOM_FULFILLED, DELETE_BREAKOUT_ROOM_PENDING, DELETE_BREAKOUT_ROOM_REJECTED } from './types';
import { EVENT_STATUS_UPDATED, UPDATE_EVENT_STATUS_FULFILLED, UPDATE_EVENT_STATUS_PENDING, UPDATE_EVENT_STATUS_REJECTED } from './types';


const event = (state: IEvent = { loading: true }, action: IEventActions): IEvent => {
    switch (action.type) {
        case FETCH_EVENT_PENDING: {
            return Object.assign({}, state, { loading: true });
        }
        case FETCH_EVENT_FULFILLED: {
            return Object.assign({}, state, { loading: false, event: action.payload.data });
        }
        case FETCH_EVENT_REJECTED: {
            if (action.payload.response) {
                return Object.assign({}, state, { loading: false, error: action.payload.response.data.message });
            } else {
                return Object.assign({}, state, { loading: false, error: action.payload.message });
            }
        }
        case UPDATE_EVENT_STATUS_PENDING: {
            return Object.assign({}, state, {
                event: Object.assign({}, state.event, {
                    loading: true,
                    error: null
                })
            });
        }
        case UPDATE_EVENT_STATUS_FULFILLED: {
            return Object.assign({}, state, {
                event: Object.assign({}, state.event, action.payload.data, { error: null, loading: false, })
            });
        }
        case UPDATE_EVENT_STATUS_REJECTED: {
            if (action.payload.response) {
                return Object.assign({}, state, {
                    event: Object.assign({}, state.event, {
                        loading: false,
                        error: action.payload.response.data.message
                    })
                });
            } else {
                return Object.assign({}, state, {
                    event: Object.assign({}, state.event, {
                        loading: false,
                        error: action.payload.message
                    })
                });
            }
        }
        case UPDATE_BREAKOUT_ROOM_PENDING: {
            return Object.assign({}, state, {
                event: Object.assign({}, state.event, {
                    error: null,
                    loading: true
                })
            });
        }
        case UPDATE_BREAKOUT_ROOM_FULFILLED: {
            return Object.assign({}, state, {
                event: Object.assign({}, state.event, {
                    error: null,
                    loading: false,
                    breakout_room: action.payload.data
                })
            });
        }
        case UPDATE_BREAKOUT_ROOM_REJECTED: {
            if (action.payload.response) {
                return Object.assign({}, state, {
                    event: Object.assign({}, state.event, {
                        loading: false,
                        error: action.payload.response.data.message
                    })
                });
            } else {
                return Object.assign({}, state, {
                    event: Object.assign({}, state.event, {
                        loading: false,
                        error: action.payload.message
                    })
                });
            }
        }
        case DELETE_BREAKOUT_ROOM_PENDING: {
            return Object.assign({}, state, {
                event: Object.assign({}, state.event, {
                    loading: true,
                    error: null
                })
            });
        }
        case DELETE_BREAKOUT_ROOM_FULFILLED: {
            return Object.assign({}, state, {
                event: Object.assign({}, state.event, {
                    error: null,
                    loading: false,
                    breakout_room: false
                })
            });
        }
        case DELETE_BREAKOUT_ROOM_REJECTED: {
            if (action.payload.response) {
                return Object.assign({}, state, {
                    event: Object.assign({}, state.event, {
                        loading: false,
                        error: action.payload.response.data.message
                    })
                });
            } else {
                return Object.assign({}, state, {
                    event: Object.assign({}, state.event, {
                        loading: false,
                        error: action.payload.message
                    })
                });
            }
        }
        case EVENT_STATUS_UPDATED: {
            return Object.assign({}, state, { event: Object.assign({}, state.event, action.payload) });
        }
        case BREAKOUT_ROOM_CREATED: {
            return Object.assign({}, state, { event: Object.assign({}, state.event, { breakout_room: true }) });
        }
        case BREAKOUT_ROOM_DELETED: {
            delete state?.event?.breakout_room
            return Object.assign({}, state, { event: Object.assign({}, state.event) });
        }
        default: {
            return state;
        }
    }
}

export default event;