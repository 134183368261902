import { memo } from 'react';
import styles from './styles.module.css'

const Spinner = ({ className }: any) => {
    return (
        <svg className={`${styles.spinner} ${className}`} viewBox="0 0 51 51" >
            <path fill="#ffffff" d="M0.571045 25.2186C0.571045 11.4159 11.7604 0.226562 25.5631 0.226562C39.3658 0.226562 50.5552 11.4159 50.5552 25.2186C50.5552 29.5073 49.4749 33.5437 47.5717 37.0706L44.9748 34.4737C46.3136 31.6706 47.0631 28.5322 47.0631 25.2188C47.0631 13.3446 37.4372 3.71875 25.5631 3.71875C13.689 3.71875 4.06311 13.3446 4.06311 25.2188C4.06311 37.0929 13.689 46.7188 25.5631 46.7188C29.0072 46.7188 32.2621 45.9089 35.1479 44.4693L37.7318 47.0533C34.1307 49.0645 29.9808 50.2107 25.5631 50.2107C11.7604 50.2107 0.571045 39.0214 0.571045 25.2186Z" />
        </svg>
    )
}

export default memo(Spinner);