import { FC, memo } from 'react';
import styles from './styles.module.css';
import { Link } from 'react-router-dom';
import { domain } from '../../config.json';
import SharedStudiosLogo from '../../images/shared-studios-logo.png';
import { SocialMedia } from '../../redux/reducers/event-reducer/types';

interface Props {
    logo: string
    orgId: string
    socialMedias: SocialMedia[]
}

const Footer: FC<Props> = ({ logo, orgId, socialMedias }) => {
    return (
        <div className={styles.footer}>
            <div className={styles.logos}>
                <Link to={`/${orgId}`}>
                    <img className={styles.logo} alt={orgId} src={`${domain}/${logo}`} />
                </Link>
                <img className={styles.logo} src={SharedStudiosLogo} alt="" />
            </div>
            <div className={styles.privacy_policy}>
                <p>© Shared Studios  2021</p>
                <a href="https://www.sharedstudios.com/vcsp-privacy-policy">Privacy Policy</a>
                <a href="https://www.sharedstudios.com/vcsp-tos">Terms</a>
            </div>
            <div className={styles.social_media}>
                {socialMedias.map((socialMedia, i) => <a key={i} href={socialMedia.url} target="_blank" rel="noreferrer">{socialMedia.name}</a>)}
            </div>
        </div>
    )
}

export default memo(Footer);