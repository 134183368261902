import { FC, memo, useState } from 'react';
import styles from './styles.module.css';
import Icon from '../../../icon';
import LearnMore from './learn-more';
import EventOverView from './event-overview';
import InvitedGuests from './invited-guests';
import { domain } from '../../../../config.json';
import { Guest } from "../../../../redux/reducers/event-reducer/types";
import SharedStudiosLogo from '../../../../images/shared-studios-logo.png';

interface Props {
    logo: string
    title: string
    guests: Guest[]
    description: string
    learnMore: string
}

const Header: FC<Props> = ({ logo, title, guests, description, learnMore }) => {
    const [currentMenu, setMenu] = useState(0);

    return (
        <div className={styles.header}>
            <img className={styles.logo} src={`${domain}/${logo}`} alt="" />
            <p className={styles.partnership_text}>in partnership with</p>
            <img className={styles.logo} src={SharedStudiosLogo} alt="" />
            <div className={styles.info_panel}>
                <div className={styles.menu}>
                    <button className={`${styles.button} ${(currentMenu === 1) && styles.underling}`} onClick={() => setMenu(1)}>event overview</button>
                    <button className={`${styles.button} ${(currentMenu === 2) && styles.underling}`} onClick={() => setMenu(2)}>invited guests</button>
                    {learnMore && <button className={`${styles.button} ${(currentMenu === 3) && styles.underling}`} onClick={() => setMenu(3)}>Learn more <Icon className={styles.info_icon} icon="info" /></button>}
                </div>
                {(currentMenu === 1) && <EventOverView title={title} description={description} onClose={() => setMenu(0)} />}
                {(currentMenu === 2) && <InvitedGuests guests={guests} onClose={() => setMenu(0)} />}
                {(currentMenu === 3) && <LearnMore learnMore={learnMore} onClose={() => setMenu(0)} />}
            </div>
        </div>
    )
}

export default memo(Header);