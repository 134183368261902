import { FC, useState, useEffect, useRef, memo } from 'react';
import styles from './styles.module.css';
import Icon from '../../../../../icon';
import { animated } from 'react-spring';
import { domain } from '../../../../../../config.json';
import useIsTrackPlayable from '../../../../../../hooks/use-play-video-track';
import { IParticipant } from '../../../../../../redux/reducers/meeting-reducer/types';

interface Props extends IParticipant {
    style?: any
}

const Participant: FC<Props> = ({ style, videoTrack, audio, first_name, last_name, profile, raised_hand }) => {
    const ref = useRef<HTMLVideoElement>(null);
    const [isPlaying, setPlaying] = useState(false);
    const [isPlayable] = useIsTrackPlayable(videoTrack);

    useEffect(() => {
        if (ref.current && videoTrack && isPlayable) {
            const elem = videoTrack.attach(ref.current);
            elem.muted = true;
            const handlePlay = () => {
                elem.play().then(() => setPlaying(true)).catch((error) => {
                    console.error(error.message);
                    return elem.play();
                });
            }
            if (elem.paused) handlePlay();
            elem.addEventListener("pause", handlePlay);
            return () => {
                elem.removeEventListener("pause", handlePlay);
                videoTrack.detach(elem);
                setPlaying(false);
            };
        }
    }, [ref, videoTrack, isPlayable]);

    return (
        <animated.div style={style} className={styles.participant} onContextMenu={(e) => e.preventDefault()}>
            {raised_hand && <Icon className={styles.raised_hand} icon="raise-hand-emoji" />}
            <p className={styles.name}><Icon className={styles.mic} icon={audio ? "mic" : "mic-mute"} />{first_name} {last_name}</p>
            {!isPlaying && <img className={styles.avatar} src={`${domain}/${profile}`} alt={`${first_name} ${last_name}`} />}
            {isPlayable && <video ref={ref} className={`${isPlaying ? styles.video : styles.hide}`} controls={false} playsInline={true} />}
        </animated.div>
    )
}

export default memo(Participant);