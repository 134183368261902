import React from 'react';
import styles from './styles.module.css';
import Icon from '../../../../../../icon';

const RaiseHandItem = ({ name }) => {
    return (
        <div className={styles.raised_hand}>
            <div className={styles.raise_hand_icon}>
                <Icon className={styles.icon} icon="raise-hand" />
            </div>
            <p className={styles.name}>{name}</p>
        </div>
    );
};

export default React.memo(RaiseHandItem);