import { FC, Fragment, memo, useState } from 'react';
import styles from './styles.module.css';
import EndCallButton from '../button';
import MenuButton from '../menu-button';
import EndCallModal from './end-call-modal';
import EndEventModal from './end-event-modal';
import Button from '../../../../../../button';

const EndCall: FC<{ type: string | undefined }> = ({ type }) => {
    const [modal, toggleModal] = useState(0);

    if (type === "moderator") {
        return (
            <Fragment>
                <MenuButton className={styles.end_call} icon="end-call">
                    <Button className={styles.button} onClick={() => toggleModal(1)}>End the Event</Button>
                    <Button className={styles.button} onClick={() => toggleModal(2)}>Leave the Event</Button>
                </MenuButton>
                {(modal === 1) && <EndEventModal close={() => toggleModal(0)} />}
                {(modal === 2) && <EndCallModal close={() => toggleModal(0)} />}
            </Fragment>
        )
    } else {
        return (
            <Fragment>
                <EndCallButton style={{ background: "#C0372A" }} onClick={() => toggleModal(2)} icon="end-call" />
                {(modal === 2) && <EndCallModal close={() => toggleModal(0)} />}
            </Fragment>
        )
    }
}

export default memo(EndCall);