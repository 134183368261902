import { FC, memo, useRef, useState } from 'react';
import styles from './styles.module.css';
import VideoControl from "./video";
import AudioControl from "./audio";
import { domain } from '../../../../../../config.json';
import { RootState } from '../../../../../../../index';
import { useSelector, shallowEqual } from 'react-redux';
import usePlayLocalTrack from '../../../../../../hooks/use-play-local-track';

const SelfieView: FC = () => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [videoTrack, setVideoTrack] = useState(null);
    const [error, serError] = useState({ camera: null, mic: null });
    const [isVideoPlaying] = usePlayLocalTrack(videoTrack, videoRef);
    const { profile } = useSelector(({ user }: RootState) => ({ profile: user.profile }), shallowEqual);

    return (
        <div className={styles.selfie_view} onContextMenu={(e) => e.preventDefault()}>
            {!isVideoPlaying && <img className={styles.avatar} src={`${domain}/${profile}`} alt="" />}
            <video ref={videoRef} className={`${isVideoPlaying ? styles.video : styles.hide}`} controls={false} />
            <div className={styles.controls}>
                <VideoControl setError={serError} setTrack={setVideoTrack} />
                <AudioControl setError={serError} />
            </div>
            {(error.camera || error.mic) && <div className={styles.error}>
                {error.mic && <p>{error.mic}</p>}
                {error.camera && <p>{error.camera}</p>}
            </div>}
        </div >
    )
}

export default memo(SelfieView);