import { FC, Fragment, memo } from 'react';
import styles from './styles.module.css';
import Button from './button';
import EndCall from './end-call';
import VideoControl from "./video";
import AudioControl from "./audio";
import BreakoutRoom from './breakout-room';
import { RootState } from '../../../../../../../index';
import { RaiseHand, LowerHand } from '../../../../../../action/user';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

const Controls: FC = () => {
    const dispatch = useDispatch();

    const { raised_hand, type } = useSelector(({ user }: RootState) => {
        return { raised_hand: user.raised_hand, type: user.type }
    }, shallowEqual);

    const handleRaiseHand = () => {
        if (raised_hand) dispatch(LowerHand())
        if (!raised_hand) dispatch(RaiseHand())
    }

    return (
        <Fragment>
            <div className={styles.controls}>
                <VideoControl />
                <AudioControl />
                <Button style={{ background: `${raised_hand ? "#85AD1E" : ""}` }} icon="raise-hand" onClick={handleRaiseHand} />
                {(type === "moderator") && <BreakoutRoom />}
                <EndCall type={type} />
            </div>
        </Fragment>
    )
}

export default memo(Controls);