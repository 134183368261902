import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import axios from '../../../../axios';
import { useParams } from "react-router-dom";
import ConnectionCard from './connection-card';

const UpcomingConnections = () => {
    const { org_id } = useParams();
    const [{ connections }, setState] = useState({ connections: null, error: null, loading: true });

    useEffect(() => {
        axios.get(`organizations/events/${org_id}`).then(({ data }) => {
            setState({ connections: data.events, error: null, loading: false })
        }).catch((error) => {
            if (error.response) {
                setState({ connections: null, error: error.response.data.message, loading: false })
            } else {
                setState({ connections: null, error: error.message, loading: false })
            }
        });
    }, [org_id]);

    if (connections) {
        return (
            <div className={styles.upcoming_connections}>
                {connections.map((connection) => <ConnectionCard key={connection.event_id} {...connection} />)}
            </div>
        )
    } else {
        return null
    }
}

export default React.memo(UpcomingConnections);