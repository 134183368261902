import React from "react";
import Modal, { Title, Text } from '../modal';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, count: 0 };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        console.error(error)
        return { hasError: true, count: 5 };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error(error, errorInfo)
        this.setState({ error, errorInfo })
    }

    componentDidUpdate() {
        if (this.state.count === 0) {
            window.location.reload();
        } else {
            setTimeout(() => {
                this.setState({ count: (this.state.count - 1) })
            }, 1000)
        }
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <Modal style={{ width: "max-content", maxWidth: "90%" }}>
                    <Title>Something went wrong!</Title>
                    <Text style={{ fontSize: "10px", textAlign: "left" }}>
                        {this.state.error && this.state.error.toString()}
                    </Text>
                    <Text style={{ fontSize: "8px", textAlign: "left", whiteSpace: 'pre-wrap' }}>
                        {this.state.errorInfo && this.state.errorInfo.componentStack}
                    </Text>
                    <Text>will auto refresh in {this.state.count}</Text>
                </Modal>
            )
        } else {
            return this.props.children;
        }
    }
}

export default ErrorBoundary;