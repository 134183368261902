import { FC, memo } from 'react';
import Button from './button';
import Settings from './menu-settings';
import MenuButton from './menu-button';
import usePublishTrack from '../../../../../../hooks/use-publish-track';
import useLocalAudioTrack from "../../../../../../hooks/use-local-audio-track";

const AudioControl: FC = () => {
    const { track, handleTrack, devices, selected, handleChange } = useLocalAudioTrack();
    const [isPublished] = usePublishTrack(track);

    if (devices.length) {
        return (
            <MenuButton onClick={handleTrack} icon={isPublished ? "mic" : "mic-mute"}>
                <Settings {...{ title: "Select a Microphone", devices, selected, handleChange }} />
            </MenuButton>
        )
    } else {
        return (
            <Button style={{ background: "#C0372A" }} disabled={true} icon="mic-mute" />
        )
    }
}

export default memo(AudioControl);