import { memo, forwardRef } from 'react';
import styles from './styles.module.css';
import { animated } from 'react-spring';
import { domain } from '../../../../../../config.json';
import { IMessage } from "../../../../../../redux/reducers/messages-reducer/types";

interface Props extends React.HTMLProps<HTMLDivElement>, IMessage {
    style: any
}

const Notification = forwardRef<HTMLDivElement, Props>(({ style, message, first_name, last_name, profile }, ref) => {
    return (
        <animated.div style={style} className={styles.notification}>
            <img className={styles.avatar} src={`${domain}/${profile}`} alt='' />
            <div className={styles.bubble} ref={ref}>
                <div className={`${styles.name}`}>{first_name} {last_name}</div>
                <div className={`${styles.content}`}>{message}</div>
            </div>
        </animated.div>
    )
});

export default memo(Notification);