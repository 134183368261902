import React, { useEffect, useState } from 'react';
import Loading from '../../../loading';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { sseURL } from '../../../../config.json';
import { newMessage } from '../../../../action/sse';

const ServerSentEvent = ({ children }: any) => {
    const dispatch = useDispatch();
    const { event_id }: any = useParams();
    const [connected, setConnect] = useState(false);
    const [socketErrorMessage, serErrorMessage] = useState<string | null>(null);

    useEffect(() => {
        const token = localStorage.getItem(`${event_id}_access_token`);
        const eventSource = new EventSource(`${sseURL}/stream?authorization=${token}`);

        const onMessage = (e: MessageEvent<any>) => {
            dispatch(newMessage(e));
        };
        const onOpen = (e: Event) => {
            setConnect(true)
        }
        const onError = (e: Event) => {
            serErrorMessage("Having trouble connecting. Please refresh the page.");
        }

        eventSource.addEventListener('open', onOpen);
        eventSource.addEventListener('error', onError);
        eventSource.addEventListener('message', onMessage);
        return () => {
            eventSource.removeEventListener('open', onOpen);
            eventSource.removeEventListener('error', onError);
            eventSource.removeEventListener('message', onMessage);
            eventSource.close();
        }
    }, [dispatch, event_id]);

    if (connected) {
        return children;
    } else if (socketErrorMessage) {
        return <Loading message={socketErrorMessage} stopLoading />
    } else {
        return <Loading message="Connecting to event. Please wait." />
    }
}

export default React.memo(ServerSentEvent);
