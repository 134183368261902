import { FC, memo, useEffect } from 'react';
import styles from './styles.module.css';
import WaitingRoom from './waiting-room';
import MeetingRooms from './meeting-rooms';
import { RootState } from '../../../../../index';
import { joinMeeting } from '../../../../action/meeting';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import FlowerSpinner from '../../../loading-animation/flower-spinner';

const VideoFeed: FC = () => {
    const dispatch = useDispatch();
    const { is_started, is_breakout_room, room, loading, error } = useSelector(({ meeting, event }: RootState) => {
        return {
            room: meeting.room,
            error: meeting.error,
            loading: meeting.loading,
            is_started: event?.event?.is_started,
            is_breakout_room: !!event?.event?.breakout_room,
        }
    }, shallowEqual);

    useEffect(() => {
        if (is_started) dispatch(joinMeeting(is_breakout_room));
    }, [dispatch, is_started, is_breakout_room]);

    if (!is_started) {
        return <WaitingRoom />
    } else if (loading) {
        return (
            <div className={styles.loading}>
                <FlowerSpinner className={styles.flower_spinner} />
                <p className={styles.message}>Joining...</p>
            </div>
        )
    } else if (error) {
        return (
            <div className={styles.loading}>
                <p className={styles.error}>ERROR: {error}</p>
            </div>
        )
    } else if (room) {
        return <MeetingRooms room={room} isBreakoutRoom={is_breakout_room} />
    } else {
        return (
            <div className={styles.loading}>
                <p className={styles.error}>ERROR: Unknown state</p>
            </div>
        )
    }
}

export default memo(VideoFeed);