import { memo } from 'react';
import styles from './styles.module.css'
import Spinner from '../loading-animation/spinner';

const Loading = ({ className, message, stopLoading }: any) => {
    return (
        <div className={`${styles.loading} ${className}`}>
            {!stopLoading && <Spinner />}
            {message && <p className={styles.message}>{message}</p>}
        </div>
    )
}

export default memo(Loading);