import { FC, Fragment, memo, useEffect } from 'react';
import Controls from './controls';
import MainRoom from './main-room';
import BreakoutRoom from './breakout-room';
import { useDispatch } from 'react-redux';
import PlayAudioTracks from './play-audio-tracks';
import { Room, RemoteParticipant } from 'twilio-video';
import { participantJoined, participantLeft, } from '../../../../../action/meeting';
import { dominantSpeakerChanged, updateParticipant, disconnectedFromMeeting } from '../../../../../action/meeting';

interface Props {
    room: Room
    isBreakoutRoom: boolean
}

const MeetingRooms: FC<Props> = ({ room, isBreakoutRoom }) => {
    const dispatch = useDispatch();

    // setting up the room
    useEffect(() => {
        const trackSubscribed = (_1: any, _2: any, rp: RemoteParticipant) => {
            dispatch(updateParticipant(rp));
        }
        const trackUnsubscribed = (_1: any, _2: any, rp: RemoteParticipant) => {
            dispatch(updateParticipant(rp));
        }
        const updateLocalParticipant = () => {
            dispatch(updateParticipant(room.localParticipant));
        }
        const onDominantSpeakerChanged = (rp: RemoteParticipant) => {
            dispatch(dominantSpeakerChanged(rp));
        }
        const updateParticipantState = (rp: RemoteParticipant) => {
            if (rp.state === "disconnected") {
                dispatch(participantLeft(rp))
            } else if (rp.state === "connected") {
                dispatch(participantJoined(rp));
            } else {
                dispatch(updateParticipant(rp));
            }
        }
        const onBeforeUnload = (e: BeforeUnloadEvent) => {
            e.preventDefault();
            e.returnValue = '';
        }
        const onUnload = () => {
            room.localParticipant.tracks.forEach((p) => p.unpublish());
            room.disconnect();
        }

        window.addEventListener("unload", onUnload);
        window.addEventListener("beforeunload", onBeforeUnload);

        // add participant's track event listeners
        room.on('trackSubscribed', trackSubscribed);
        room.on('trackUnsubscribed', trackUnsubscribed);

        // add participant event listeners
        room.on('participantConnected', updateParticipantState);
        room.on('participantReconnected', updateParticipantState);
        room.on('participantReconnecting', updateParticipantState);
        room.on('participantDisconnected', updateParticipantState);

        room.on('dominantSpeakerChanged', onDominantSpeakerChanged);

        room.localParticipant.on('trackStopped', updateLocalParticipant);
        room.localParticipant.on('trackPublished', updateLocalParticipant);

        room.on("disconnected", () => dispatch(disconnectedFromMeeting()));
        return () => {
            // remove participant's track event listeners
            onUnload();
            room.removeAllListeners();
            window.removeEventListener("unload", onUnload);
            window.removeEventListener("beforeunload", onBeforeUnload);
        }
    }, [room, dispatch]);

    return (
        <Fragment>
            <Controls />
            <PlayAudioTracks room={room} />
            {isBreakoutRoom ? <BreakoutRoom /> : <MainRoom />}
        </Fragment>
    )
}

export default memo(MeetingRooms);