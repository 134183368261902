import { FC, memo, useEffect, useState } from 'react';
import styles from './styles.module.css';
import moment from 'moment';
import 'moment-duration-format';

interface Props extends React.HTMLProps<HTMLDivElement> {
    date: number
}

const Countdown: FC<Props> = ({ className, date }) => {
    const eventDate = moment(date);
    const [countDown, setCountDown] = useState([0, 0, 0, 0])

    useEffect(() => {
        const formatTime = (duration: any) => {
            setCountDown(duration.format("dd:hh:mm:ss", { trim: false }).split(':'))
        }
        const stopInterval = setInterval(() => {
            const difference = eventDate.diff(moment(), 'seconds')
            const timeLeft = moment.duration(difference, 'seconds')
            if (timeLeft.asSeconds() <= 0) {
                clearInterval(stopInterval)
                formatTime(moment.duration(0))
            } else {
                formatTime(timeLeft)
            }
        }, 1000)
        return () => clearInterval(stopInterval);
    }, [eventDate])

    return (
        <div className={`${styles.count_down} ${className}`}>
            {(countDown[0] > 0) && <div className={styles.count}>
                <div className={styles.number}>{countDown[0]}</div>
                <div className={styles.unit}>{countDown[0] > 1 ? 'Days' : 'Day'}</div>
            </div>}
            <div className={styles.count}>
                <div className={styles.number}>{countDown[1]}</div>
                <div className={styles.unit}>{countDown[1] > 1 ? 'Hours' : 'Hour'}</div>
            </div>
            <div className={styles.count}>
                <div className={styles.number}>{countDown[2]}</div>
                <div className={styles.unit}>{countDown[2] > 1 ? 'Minutes' : 'Minute'}</div>
            </div>
            <div className={styles.count}>
                <div className={styles.number}>{countDown[3]}</div>
                <div className={styles.unit}>{countDown[3] > 1 ? 'Seconds' : 'Second'}</div>
            </div>
        </div>
    )
}

export default memo(Countdown);