import { HAND_RAISED, HAND_LOWERED } from './types';
import { IActions, IRaisedHand } from './types';


const raisedHand = (state: IRaisedHand[] = [], { type, payload }: IActions) => {
    switch (type) {
        case HAND_RAISED: {
            return [...state, payload];
        }
        case HAND_LOWERED: {
            return state.filter(({ user_id }) => user_id !== payload);
        }
        default: {
            return state;
        }
    }
}

export default raisedHand;