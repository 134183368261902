import { FC, memo } from 'react';
import styles from './styles.module.css';
import Button from '../../../../../../button';
import { useDispatch } from 'react-redux';
import Modal, { Title, Text } from '../../../../../../modal';
import { LOG_OUT } from '../../../../../../../redux/reducers/user-reducer/types';

interface Props {
    close: () => void
}

const EndCallModal: FC<Props> = ({ close }) => {
    const dispatch = useDispatch();

    const handleEndCall = () => {
        localStorage.clear();
        dispatch({ type: LOG_OUT });
    }

    return (
        <Modal>
            <Title>Sure you want to leave?</Title>
            <Text>Only you will be disconnected from the event.</Text>
            <div className={styles.modal_buttons}>
                <Button onClick={close} style={{ background: "#E1EDCC", color: "#545050" }}>Cancel</Button>
                <Button onClick={handleEndCall} style={{ background: "#C0372A" }}>Leave the Event</Button>
            </div>
        </Modal>
    )
}

export default memo(EndCallModal);