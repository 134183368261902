import { FC, memo } from 'react';
import styles from './styles.module.css';
import { domain } from '../../config.json';

interface Guest {
    name: string
    title: string
    profile: string
}

interface Props {
    about: string
    guests: Guest[]
}

const AboutEvent: FC<Props> = ({ about, guests }) => {
    return (
        <div className={styles.about_event}>
            <p className={styles.title}>About the event</p>
            <p className={styles.details}>{about}</p>
            {guests.length > 0 && <p className={styles.title}>Guests</p>}
            {guests.length > 0 && <div className={styles.guests}>
                {guests.map((guest) => (
                    <div key={guest.name} className={styles.guest}>
                        <img className={styles.guest_image} src={`${domain}/${guest.profile}`} alt={guest.name} />
                        <p className={styles.guest_name}>{guest.name}</p>
                        <p className={styles.guest_title}>{guest.title}</p>
                    </div>
                ))}
            </div>}
        </div>
    )
}

export default memo(AboutEvent);