import axios from '../axios'
import { Dispatch } from 'redux';
import { DispatchFetchEvent, FETCH_EVENT } from '../redux/reducers/event-reducer/types';
import { DispatchUpdateEventStatus, UPDATE_EVENT_STATUS } from '../redux/reducers/event-reducer/types';
import { DispatchDeleteBreakoutRoom, DELETE_BREAKOUT_ROOM } from '../redux/reducers/event-reducer/types';
import { EventStatus, DispatchUpdateBreakoutRoom, UPDATE_BREAKOUT_ROOM, BreakoutRoom } from '../redux/reducers/event-reducer/types';

export const fetchEvent = (eventId: string) => {
    return (dispatch: Dispatch<DispatchFetchEvent>) => {
        dispatch({ type: FETCH_EVENT, payload: axios.get(`events/${eventId}`) });
    }
}

export const updateEventStatus = (data: EventStatus) => {
    return (dispatch: Dispatch<DispatchUpdateEventStatus>) => {
        dispatch({ type: UPDATE_EVENT_STATUS, payload: axios.post('events/update-status', data) });
    }
}

export const updateBreakoutRoom = (data: BreakoutRoom) => {
    return (dispatch: Dispatch<DispatchUpdateBreakoutRoom>) => {
        dispatch({ type: UPDATE_BREAKOUT_ROOM, payload: axios.post('events/breakout-room', data) });
    }
}

export const deleteBreakoutRoom = () => {
    return (dispatch: Dispatch<DispatchDeleteBreakoutRoom>) => {
        dispatch({ type: DELETE_BREAKOUT_ROOM, payload: axios.delete('events/breakout-room') });
    }
}