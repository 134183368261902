export const HAND_RAISED = "HAND-RAISED";
export const HAND_LOWERED = "HAND-LOWERED";

export interface IRaisedHand {
    name: string
    user_id: string
}

export interface handLowered {
    type: typeof HAND_LOWERED
    payload: string
}


export interface handRaised {
    type: typeof HAND_RAISED
    payload: {
        user_id: string
        name: string
    }
}

export type IActions = handLowered | handRaised