import { FC, memo, useState } from 'react';
import styles from './styles.module.css';
import Icon from '../../../../../../icon';
import Participant from '../../participant';
import { RootState } from '../../../../../../../../index';
import { shallowEqual, useSelector } from 'react-redux';

const GalleryView: FC = () => {
    const [page, setPage] = useState(0);
    const { users, page_length, total, guests } = useSelector(({ meeting }: RootState) => {
        let users = meeting.participants;
        const moderator = meeting.moderator;
        let total = meeting.total_participants;
        let totalGuests = meeting.total_guests;

        if (totalGuests >= 4 && moderator) {
            total += 1;
            users = [moderator, ...users];
            return { users: users, total: users.length, page_length: totalGuests > 3 ? 3 : 5, guests: totalGuests };
        } else {
            totalGuests = (totalGuests < 4) ? totalGuests + 1 : totalGuests;
            return { users, total, page_length: totalGuests > 3 ? 3 : 5, guests: totalGuests };
        }
    }, shallowEqual);


    const handleLeft = () => {
        if (page - page_length <= 0) setPage(0)
        else setPage(page - page_length)
    }

    const handleRight = () => {
        const itemsLeft = (total - (page + page_length))
        if (itemsLeft > page_length) return setPage(page + page_length)
        setPage((page + page_length) - (page_length - itemsLeft))
    }

    return (
        <div className={`${styles.gallery_view} ${styles[`gallery_view_${guests}`]}`}>
            {(total > page_length) &&
                <button
                    className={`${styles.scroll} ${page_length === 3 ? styles.up : styles.left}`}
                    onClick={handleLeft}
                    disabled={page === 0}
                >
                    <Icon className={styles.icon} icon="arrow" />
                </button>}
            {users.slice(page, page + page_length).map((p) => <Participant key={p.sid} {...p} />)}
            {(total > page_length) &&
                <button
                    className={`${styles.scroll} ${page_length === 3 ? styles.down : styles.right}`}
                    onClick={handleRight}
                    disabled={(total - (page + page_length) === 0)}
                >
                    <Icon className={styles.icon} icon="arrow" />
                </button>}
        </div>
    )
}

export default memo(GalleryView);