import { FC, memo, ReactNode } from 'react';
import styles from './styles.module.css';
import BouncingDots from "../loading-animation/bouncing-dots";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    loading?: boolean
    className?: string
    children: ReactNode
    style?: React.CSSProperties

}

const Button: FC<Props> = ({ className, children, loading, style, ...rest }) => {
    return (
        <button className={`${styles.button} ${className}`} {...rest} style={style}>
            {loading ? <BouncingDots /> : children}
        </button >
    );
}

export default memo(Button);