import { FC, memo, useEffect } from 'react';
import Loading from '../../loading';
import LoginPage from "../login-page";
import EventPage from "../event-page";
import { useParams } from 'react-router';
import { RootState } from '../../../../index';
import { fetchProfile } from '../../../action/user';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { LOG_OUT } from '../../../redux/reducers/user-reducer/types';

const Authentication: FC = () => {
    const dispatch = useDispatch();
    const { event_id } = useParams<any>();
    const { loading, logged_in } = useSelector(({ user }: RootState) => {
        return {
            loading: user.loading,
            logged_in: user.logged_in
        }
    }, shallowEqual);

    useEffect(() => {
        const token = localStorage.getItem(`${event_id}_access_token`);
        if (token) {
            dispatch(fetchProfile(token))
        } else {
            dispatch({ type: LOG_OUT });
        }
    }, [event_id, dispatch]);

    if (loading) {
        return <Loading />
    } else if (logged_in) {
        return <EventPage />
    } else {
        return <LoginPage />
    }
}

export default memo(Authentication);