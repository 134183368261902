import { FC, memo } from 'react';
import TextInput from '../../../text-input';
import styles from './styles.module.css';
import Button from '../../../button';
import axios from '../../../../axios';
import useValidation from './useValidation';
import { useParams } from "react-router-dom";

interface Props {
    orgName: string
}

const LoginForm: FC<Props> = ({ orgName }) => {
    const { event_id } = useParams<any>();
    const [values, isSubmitting, errors, handleChange, handleSubmit] = useValidation(submit);

    function submit() {
        const config = { headers: { "Content-Type": "application/json" } }
        return axios.post(`/users/authenticate/${event_id}`, values, config).then(({ data }) => {
            localStorage.setItem(`${event_id}_access_token`, data.token);
            window.location.reload();
        });
    }

    return (
        <div className={styles.register_form}>
            <div className={styles.wrapper}>
                <div className={styles.title}>
                    <p>Join the event</p>
                    <p className={styles.details}>Sign in using the unique password sent to your email. When the event starts, you will join the conversation live.</p>
                </div>
                <div className={styles.form}>
                    {errors.message && <div className={styles.error_message}>{errors.message}</div>}
                    <TextInput
                        label="Email address"
                        type="email"
                        name="email"
                        value={values.email}
                        error={errors.email}
                        onChange={handleChange}
                    />
                    <TextInput
                        label="Password"
                        type="password"
                        name="password"
                        value={values.password}
                        error={errors.password}
                        onChange={handleChange}
                    />
                    <div className={styles.tc_privacy}>
                        <input className={`${styles.tc_checkbox} ${errors.tc && styles.not_checked}`} type="checkbox" name="tc" onChange={handleChange} checked={values.tc} />
                        <p className={styles.tc_text}>By checking this box I acknowledge that I have read and accepted the <a href="https://www.sharedstudios.com/" target="_blank" rel="noreferrer">Terms & Conditions</a> and the  <a href="https://www.sharedstudios.com/" target="_blank" rel="noreferrer">Privacy Policy</a>. </p>
                        {errors.tc && <p className={styles.tc_error}>You must accept the terms and conditions before logging in.</p>}
                    </div>
                    <div className={styles.allow_record}>
                        <input className={`${styles.tc_checkbox} ${errors.allowRecord && styles.not_checked}`} type="checkbox" name="allowRecord" onChange={handleChange} checked={values.allowRecord} />
                        <p className={styles.tc_text}>By checking this box, I hereby grant Shared Space Studios, LLC and {orgName} the right and authorization to record, edit, alter, copy, and distribute part or all of the Event(s), including for promotional use in social media, without payment or any further consideration. This authorization extends to publication of a recording of the Event.</p>
                        {errors.allowRecord && <p className={styles.tc_error}>You must grant the right and authorization before logging in.</p>}
                    </div>
                </div>
                <Button onClick={handleSubmit} loading={isSubmitting} disabled={errors.email || errors.password || errors.tc || errors.allowRecord}>Join Now</Button>
            </div>
        </div>
    )
}

export default memo(LoginForm);