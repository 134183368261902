import { FC, memo, useState, useEffect } from 'react';
import styles from './styles.module.css';
import Button from '../button';
import Icons from '../../../../../../icon';

interface Props {
    icon: string
    className?: string
    onClick?: () => void
    children: React.ReactNode
}

const ButtonWithMenu: FC<Props> = ({ className, onClick, children, icon }) => {
    const [showMenu, toggleMenu] = useState<boolean>(false);

    useEffect(() => {
        if (showMenu) {
            const hideMenu = () => toggleMenu(false);
            document.addEventListener("click", hideMenu)
            return () => {
                document.removeEventListener("click", hideMenu)
            }
        }
    }, [showMenu, toggleMenu])

    return (
        <div className={`${className} ${styles.menu_button}`}>
            <Button className={`${showMenu && styles.menu_active}`} onClick={onClick ? onClick : () => toggleMenu(!showMenu)} icon={icon} />
            {onClick && <button className={`${styles.arrow} ${showMenu && styles.active}`} onClick={() => toggleMenu(!showMenu)}>
                <Icons className={styles.icon} icon="arrow-mini" />
            </button>}
            {showMenu && <div className={styles.menu}>
                <div className={styles.container}>{children}</div>
            </div>}
        </div>
    )
}

export default memo(ButtonWithMenu);