import React from 'react';
import styles from './styles.module.css';
import moment from 'moment';
import { Link } from "react-router-dom";
import { domain } from '../../../../../config.json';

const ConnectionCard = ({ setConnectionInfo, cover_image, date, title, short_description, org_id, event_id, ...rest }) => {
    return (
        <div className={styles.upcoming_connection_card}>
            <img className={styles.image} alt={title} aria-label={title} src={`${domain}/${cover_image}`} />
            <div className={styles.card_info}>
                <p className={styles.date}>{date ? moment(date).format("MMMM Do YYYY") : "TBD"}</p>
                <p className={styles.title}>{title}</p>
                <p className={styles.short_description}>{short_description}</p>
                <Link className={styles.learn_more_button} to={`${org_id}/${event_id}`}>Learn more</Link>
            </div>
        </div>
    )
}

export default React.memo(ConnectionCard);