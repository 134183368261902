import { FC, memo, useState } from 'react';
import TextInput from '../../../text-input';
import styles from './styles.module.css';
import Button from '../../../button';
import axios from '../../../../axios';
import { useHistory } from "react-router";
import useValidation from './useValidation';
import { Title, Text } from "../../../modal";
import { useParams } from "react-router-dom";
import emoji from "../../../../images/party-popper.png";

interface Props {
    orgId: string
}

const RegisterForm: FC<Props> = ({ orgId }) => {
    const history = useHistory()
    const { event_id } = useParams<any>();
    const [isSuccess, success] = useState(false);
    const [values, isSubmitting, errors, handleChange, handleSubmit] = useValidation(submit);

    function submit() {
        const config = { headers: { "Content-Type": "application/json" } }
        return axios.put(`/users/register/${event_id}`, values, config).then(() => success(true));
    }

    if (isSuccess) {
        return (
            <div className={`${styles.register_form} ${styles.register_success}`}>
                <img className={styles.celebrate} src={emoji} alt="celebrate" />
                <Title style={{ color: "#686262", fontSize: "40px", lineHeight: "48px" }}>Success</Title>
                <Text style={{ color: "#686262", fontSize: "24px" }}>Your form has been submitted.</Text>
                <Button style={{ marginTop: "20px" }} onClick={() => history.push(`/${orgId}`)}>Explore more events</Button>
            </div>
        )
    } else {
        return (
            <div className={styles.register_form}>
                <div className={styles.wrapper}>
                    <div className={styles.title}>
                        <p>Register</p>
                        <p className={styles.details}>Sign up to register for the event. You will receive a confirmation and calendar invitation within 24-48 dependent on availability of spaces.</p>
                    </div>
                    <div className={styles.form}>
                        {errors.message && <div className={styles.error_message}>
                            {errors.message}
                        </div>}
                        <TextInput
                            label="First Name"
                            type="text"
                            name="first_name"
                            value={values.first_name}
                            error={errors.first_name}
                            onChange={handleChange}
                        />
                        <TextInput
                            label="Last Name"
                            type="text"
                            name="last_name"
                            value={values.last_name}
                            error={errors.last_name}
                            onChange={handleChange}
                        />
                        <TextInput
                            label="Email address"
                            type="email"
                            name="email"
                            value={values.email}
                            error={errors.email}
                            onChange={handleChange}
                        />
                        <div className={styles.tc_privacy}>
                            <input className={`${styles.tc_checkbox} ${errors.tc && styles.not_checked}`} type="checkbox" name="tc" onChange={handleChange} checked={values.tc} />
                            <p className={styles.tc_text}>By checking this box I acknowledge that I have read and accepted the <a href="https://www.sharedstudios.com/vcsp-tos" target="_blank" rel="noreferrer">Terms & Conditions</a> and the  <a href="https://www.sharedstudios.com/vcsp-privacy-policy" target="_blank" rel="noreferrer">Privacy Policy.</a> </p>
                            {errors.tc && <p className={styles.tc_error}>You must accept the terms and conditions before registering</p>}
                        </div>
                    </div>
                    <Button onClick={handleSubmit} loading={isSubmitting} disabled={Object.values(errors).length > 0}>Submit</Button>
                </div>
            </div>
        )
    }
}

export default memo(RegisterForm);