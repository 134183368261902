import { DISCONNECTED_FROM_MEETING, DOMINANT_SPEAKER_CHANGED } from './types';
import { IUser, IUserHash, IParticipant, IMeeting, IMeetingActions } from './types';
import { RemoteParticipant, LocalParticipant, RemoteVideoTrack } from 'twilio-video';
import { JOINED_MEETING_FULFILLED, JOINED_MEETING_PENDING, JOINED_MEETING_REJECTED } from './types';
import { HAND_RAISED, HAND_LOWERED, PARTICIPANT_JOINED, PARTICIPANT_LEFT, UPDATE_PARTICIPANT } from './types';

const initialState = {
    loading: false,
    error: null,
    users: {},
    guests: [],
    total_guests: 0,
    moderator: null,
    participants: [],
    total_participants: 0,
    dominant_speaker: null
}

const meeting = (state: IMeeting = initialState, action: IMeetingActions): IMeeting => {
    switch (action.type) {
        case JOINED_MEETING_PENDING: {
            return Object.assign({}, initialState, { loading: true });
        }
        case JOINED_MEETING_FULFILLED: {
            const users: IUserHash = {};
            const guests: IParticipant[] = [];
            const participants: IParticipant[] = [];
            let moderator: IParticipant | null = null;
            // let moderator: IParticipant | null = {
            //     sid: 'moderator',
            //     first_name: 'Siam',
            //     last_name: 'Moderator',
            //     type: 'moderator',
            //     user_id: `moderator_user_id`,
            //     profile: "assets/profile/bc8b9bcd-0396-4fb5-906d-dd2ca20a9b13/sMZhVLZuvXdncjrRs7JGwD",
            // };
            // const guests: IParticipant[] = [...new Array(4)].map((_, i) => ({
            //     sid: `guest_${i}`,
            //     first_name: "Guest",
            //     last_name: `${i}`,
            //     type: 'guest',
            //     user_id: `guest_user_id${i}`,
            //     profile: "assets/profile/bc8b9bcd-0396-4fb5-906d-dd2ca20a9b13/sMZhVLZuvXdncjrRs7JGwD",
            // }));

            // const participants: IParticipant[] = [...new Array(10)].map((_, i) => ({
            //     sid: `participant_${i}`,
            //     first_name: "Participant",
            //     last_name: `${i}`,
            //     type: "participant",
            //     user_id: `participant_user_id${i}`,
            //     profile: "assets/profile/bc8b9bcd-0396-4fb5-906d-dd2ca20a9b13/sMZhVLZuvXdncjrRs7JGwD",
            // }));

            const { room } = action.payload;

            action.payload.users.forEach((user: IUser) => users[user.user_id] = user);

            [room.localParticipant, ...room.participants.values()].forEach((rp) => {
                const user = users[rp.identity]
                if (user) {
                    if (user && user.type === "guest") {
                        guests.push(createParticipant(rp, user));
                    } else if (user.type === "participant") {
                        participants.push(createParticipant(rp, user));
                    } else {
                        moderator = createParticipant(rp, user)
                    }
                }
            });

            return Object.assign({}, state, {
                room,
                users,
                guests,
                moderator,
                participants,
                loading: false,
                total_guests: guests.length,
                total_participants: participants.length,
                dominant_speaker: room.dominantSpeaker,
            });
        }
        case JOINED_MEETING_REJECTED: {
            if (!action.payload.response) {
                return Object.assign({}, initialState, { error: action.payload.message });
            } else {
                return Object.assign({}, initialState, { error: action.payload.response.data.message });
            }
        }
        case DISCONNECTED_FROM_MEETING: {
            return Object.assign({}, initialState, { error: "disconnected from meeting" });
        }
        case PARTICIPANT_JOINED: {
            const rp = action.payload;
            const user = state.users[rp.identity];
            if (user) {
                if (user.type === "guest") {
                    const guest = createParticipant(rp, user);
                    const guests = [...state.guests, guest];
                    return Object.assign({}, state, { guests, total_guests: guests.length });
                } else if (user.type === "participant") {
                    const participant = createParticipant(rp, user);
                    const participants = [...state.participants, participant];
                    return Object.assign({}, state, { participants, total_participants: participants.length });
                } else {
                    return Object.assign({}, state, { moderator: createParticipant(rp, user) });
                }
            }
            return state;
        }
        case PARTICIPANT_LEFT: {
            const rp = action.payload;
            const user = state.users[rp.identity];
            if (user) {
                if (user.type === "guest") {
                    const guests = state.guests.filter((p) => p.sid !== rp.sid);
                    return Object.assign({}, state, { guests, total_guests: guests.length });
                } else if (user.type === "participant") {
                    const participants = state.participants.filter((p) => p.sid !== rp.sid);
                    return Object.assign({}, state, { participants, total_participants: participants.length });
                } else {
                    return Object.assign({}, state, { moderator: null });
                }
            }
            return state;
        }
        case UPDATE_PARTICIPANT: {
            const rp = action.payload;
            const user = state.users[rp.identity];
            if (user) {
                if (user.type === "guest") {
                    const guests = state.guests.map((p) => {
                        if (p.sid !== rp.sid) return p;
                        return createParticipant(rp, user);
                    });
                    return Object.assign({}, state, { guests, total_guests: guests.length });
                } else if (user.type === "participant") {
                    const participants = state.participants.map((p) => {
                        if (p.sid !== rp.sid) return p;
                        return createParticipant(rp, user);
                    });
                    return Object.assign({}, state, { participants, total_participants: participants.length });
                } else {
                    return Object.assign({}, state, { moderator: createParticipant(rp, user) });
                }
            }
            return state;
        }
        case DOMINANT_SPEAKER_CHANGED: {
            if (action.payload) {
                const participants = state.participants;
                const mySid = state.room?.localParticipant.sid;
                const domSpk = participants.find((p) => p.sid === action.payload.sid)
                if (domSpk && domSpk.type === "participant" && domSpk.sid !== mySid) {
                    const sorted = participants.sort((a) => {
                        if (a.sid === domSpk.sid) return -1
                        return 0
                    }).sort((a) => {
                        if (a.sid === mySid) return -1
                        return 0
                    });
                    return Object.assign({}, state, {
                        dominant_speaker: action.payload,
                        participants: [...sorted]
                    });
                }
            }
            return Object.assign({}, state, { dominant_speaker: action.payload });
        }
        case HAND_RAISED: {
            const user = state.users[action.payload.user_id];
            if (user) {
                if (user.type === "guest") {
                    return Object.assign({}, state, {
                        guests: state.guests.map((p) => {
                            if (p.user_id !== user.user_id) return p;
                            return Object.assign({}, p, { raised_hand: true })
                        })
                    });
                } else if (user.type === "participant") {
                    return Object.assign({}, state, {
                        participants: state.participants.map((p) => {
                            if (p.user_id !== user.user_id) return p;
                            return Object.assign({}, p, { raised_hand: true })
                        })
                    });
                } else {
                    return Object.assign({}, state, { moderator: Object.assign({}, state.moderator, { raised_hand: true }) });
                }
            }
            return state;
        }
        case HAND_LOWERED: {
            const user = state.users[action.payload];
            if (user) {
                if (user.type === "guest") {
                    return Object.assign({}, state, {
                        guests: state.guests.map((p) => {
                            if (p.user_id !== user.user_id) return p;
                            return Object.assign({}, p, { raised_hand: false })
                        })
                    });
                } else if (user.type === "participant") {
                    return Object.assign({}, state, {
                        participants: state.participants.map((p) => {
                            if (p.user_id !== user.user_id) return p;
                            return Object.assign({}, p, { raised_hand: false })
                        })
                    });
                } else {
                    return Object.assign({}, state, { moderator: Object.assign({}, state.moderator, { raised_hand: false }) });
                }
            }
            return state;
        }
        default: {
            return state;
        }
    }
}

// import { RemoteVideoTrackPublication, LocalVideoTrackPublication } from 'twilio-video';
// import { RemoteAudioTrackPublication, LocalAudioTrackPublication } from 'twilio-video';

function createParticipant(rp: RemoteParticipant | LocalParticipant, user: IUser): IParticipant {
    const participant: IParticipant = Object.assign({}, user, {
        sid: rp.sid,
        audio: false,
        videoTrack: undefined,
    });

    rp.tracks.forEach((track: any) => {
        if (track.kind === "video") {
            if (track.track && "isStopped" in track.track && !track.track.isStopped) {
                participant.videoTrack = track.track as RemoteVideoTrack;
            } else if (track.track && !("isStopped" in track.track)) {
                participant.videoTrack = track.track as RemoteVideoTrack;
            }
        }
        if (track.kind === "audio") {
            if (track.track && "isStopped" in track.track && !track.track.isStopped) {
                participant.audio = true
            } else if (track.track && !("isStopped" in track.track)) {
                participant.audio = true
            }
        }
    });

    // rp.videoTracks.forEach((publication: RemoteVideoTrackPublication | LocalVideoTrackPublication) => {
    //     if (publication.track) {
    //         if ("isStopped" in publication.track && !publication.track.isStopped) {
    //             participant.videoTrack = publication.track;
    //         } else {
    //             participant.videoTrack = publication.track;
    //         }
    //     }
    // });

    // rp.audioTracks.forEach((publication: RemoteAudioTrackPublication | LocalAudioTrackPublication) => {
    //     if (publication.track) {
    //         if ("isStopped" in publication.track && !publication.track.isStopped) {
    //             participant.audio = true
    //         } else {
    //             participant.audio = true
    //         }
    //     }
    // });
    return participant
}

export default meeting;