import { FC, Fragment, memo, useEffect, useState } from 'react';
import styles from './styles.module.css';
import Hero from './hero';
import Header from '../../header';
import Footer from '../../footer';
import { AxiosError } from 'axios';
import axios from '../../../axios';
import Loading from '../../loading';
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import VideoHighlight from './video-highlight';
import UpcomingConnections from './upcoming-connections';
import PreviousConnections from './previous-connections';

interface State {
    loading: boolean
    data: any | null
    error: string | null
}

const DiscoveryPage: FC = () => {
    const { org_id } = useParams<any>();
    const [{ data, loading, error }, setState] = useState<State>({ data: null, error: null, loading: true });

    useEffect(() => {
        axios.get(`organizations/${org_id}`).then(({ data }) => {
            setState({ data, error: null, loading: false })
        }).catch((error: AxiosError) => {
            if (error.response) {
                setState({ data: null, error: error.response.data.message, loading: false })
            } else {
                setState({ data: null, error: error.message, loading: false })
            }
        });
    }, [org_id]);


    if (loading) {
        return <Loading />
    } else if (error) {
        return <Loading message={error} stopLoading />
    } else {
        return (
            <Fragment>
                <Helmet>
                    <link rel="icon" href={data.favicon} />
                    <title>{data.tab_title} | Shared_Studios</title>
                </Helmet>
                <Header logo={data.logo} orgId={data.org_id} />
                <div className={styles.discovery_page}>
                    <Hero {...data.hero} />
                    <UpcomingConnections />
                    <VideoHighlight {...data.video_highlight} />
                    <PreviousConnections />
                </div>
                <Footer logo={data.logo} orgId={data.org_id} socialMedias={data.social_medias} />
            </Fragment>
        )
    }
}

export default memo(DiscoveryPage);