import { memo, FC } from 'react';
import styles from './styles.module.css';
import VideoPlayer from '../video-player';
import { domain } from '../../../../config.json';

interface Props {
    deck: string
    video: string
    heading: string
    backdrop: string
    thumbnail: string
    description: string
}

const Hero: FC<Props> = ({ deck, heading, description, thumbnail, video, backdrop }) => {
    return (
        <div className={styles.hero}>
            <p className={styles.deck}>{deck}</p>
            <p className={styles.title}>{heading}</p>
            <p className={styles.body}>{description}</p>
            <VideoPlayer className={styles.video} color="green" src={video} thumbnail={thumbnail} />
            <img className={styles.backdrop} src={`${domain}/${backdrop}`} alt="" />
        </div>
    )
}

export default memo(Hero);