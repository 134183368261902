import { useEffect, useState } from 'react';
import { LocalVideoTrack, RemoteVideoTrack } from 'twilio-video';

type track = RemoteVideoTrack | LocalVideoTrack | undefined

const useIsTrackPlayable = (track: track) => {
    const [isPlayable, setPlayable] = useState(false);

    useEffect(() => {
        if (track) {
            if ("switchOn" in track) {
                const mediaTrack = track.mediaStreamTrack;
                const updatePlayable = () => {
                    if (track.isSwitchedOff) return track.switchOn();
                    setPlayable(!mediaTrack.muted && track.isEnabled && track.isStarted && !track.isSwitchedOff);
                }
                track.switchOn();
                track.on("started", updatePlayable);
                track.on("switchedOn", updatePlayable);
                track.on("switchedOff", updatePlayable);
                mediaTrack.addEventListener('mute', updatePlayable);
                mediaTrack.addEventListener('unmute', updatePlayable);
                return () => {
                    mediaTrack.removeEventListener('unmute', updatePlayable);
                    mediaTrack.removeEventListener('mute', updatePlayable);
                    track.removeAllListeners();
                    track.switchOff();
                }
            } else {
                setPlayable(true)
                track.on("started", () => setPlayable(true));
                track.on("stopped", () => setPlayable(false));
                return () => {
                    track.removeAllListeners()
                }
            }
        }
    }, [track]);

    return [isPlayable]
}

export default useIsTrackPlayable;