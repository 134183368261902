import { memo, FC } from 'react';
import moment from 'moment';
import styles from './styles.module.css';
import Icon from "../../../../../icon";
import { domain } from '../../../../../../config.json';
import { IMessage } from "../../../../../../redux/reducers/messages-reducer/types";

interface Props extends IMessage {
    key: any
    isUser: boolean
    resendMessage: () => void
    reference?: React.MutableRefObject<HTMLDivElement | null>
}

const Message: FC<Props> = ({ reference, isUser, message, first_name, last_name, time_stamp, profile, status, resendMessage }) => {
    return (
        <div className={`${styles.message} ${styles[isUser ? "is_user" : ""]}`} ref={reference}>
            <img className={styles.avatar} src={`${domain}/${profile}`} alt='' />
            <div className={styles.bubble}>
                <div className={`${styles.name}`}>{isUser ? "you" : `${first_name} ${last_name}`}</div>
                <div className={`${styles.content}`}>{message}</div>
                <p className={`${styles.time}`}>{moment(time_stamp).format("LT")}</p>
                {status && <Icon className={styles.status} icon={status} />}
            </div>
            {status === "rejected" && <button className={styles.try_again} onClick={resendMessage}>
                Try again <Icon className={styles.status} icon="try-again" />
            </button>}
        </div>
    )
}

export default memo(Message);