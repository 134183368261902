import { FC, memo, useMemo, useRef, useEffect, useState } from 'react';
import Message from './notification';
import styles from './styles.module.css';
import { useTransition } from 'react-spring';
import { RootState } from "../../../../../../index";
import { shallowEqual, useSelector } from 'react-redux';
import { IMessage } from '../../../../../redux/reducers/messages-reducer/types';

interface Props {
    show: () => void
}

const Notifications: FC<Props> = ({ show }) => {
    const rendered = useRef(0);
    const refMap = useMemo(() => new WeakMap(), []);
    const [messages, setMessage] = useState<IMessage[]>([]);
    const message = useSelector(({ messages: { messages } }: RootState) => messages[0], shallowEqual);

    const transitions = useTransition(messages, {
        onRest: () => setMessage([]),
        keys: (item) => item.time_stamp,
        leave: () => async (next) => {
            await next({ delay: 5000 })
            await next({ opacity: 0 })
        },
        sort: (a, b) => (a.time_stamp > b.time_stamp) ? -1 : 1,
        config: { tension: 125, friction: 20, precision: 0.1 },
        from: { opacity: 0, transform: "translateX(100%)", height: 0 },
        enter: (item) => async (next) => {
            await next({ opacity: 1, transform: "translateX(0%)", height: refMap.get(item).offsetHeight })
        },
    });

    useEffect(() => {
        if (rendered.current++) setMessage([message])
    }, [message])

    return (
        <div className={styles.notifications} onClick={show}>
            {transitions((props, m) => <Message key={m.time_stamp} style={props} {...m} ref={(elm) => elm && refMap.set(m, elm)} />)}
        </div>
    )
}

export default memo(Notifications);