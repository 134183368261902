import { FC, Fragment, memo, useState } from 'react';
import Button from '../button';
import BreakoutRoomModal from './breakout-rooms-modal';

const BreakoutRoom: FC = () => {
    const [showModal, toggle] = useState(false);
    return (
        <Fragment>
            <Button onClick={() => toggle(true)} icon="participants" />
            {showModal && <BreakoutRoomModal close={() => toggle(false)} />}
        </Fragment>
    )
}

export default memo(BreakoutRoom);