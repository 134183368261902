import { FC, memo, useState } from 'react';
import styles from './styles.module.css';
import Icon from '../../../../icon';
import { domain } from '../../../../../config.json';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from "../../../../../../index";

const Share: FC = () => {
    const [toggle, setToggle] = useState(false);
    const { org_id, event_id, shared_text } = useSelector(({ event }: RootState) => {
        return {
            org_id: event.event?.org_id,
            event_id: event.event?.event_id,
            shared_text: event.event?.shared_text
        }
    }, shallowEqual);

    const shareTo = (name: string) => {
        const FBappID = 361652398726885;
        const href = `${domain}/${org_id}/${event_id}`;
        if (name === "twitter") {
            window.open(`https://twitter.com/intent/tweet?text=${shared_text}%0A${href}`, '_blank')
        } else if (name === "linkedIn") {
            window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${href}`, '_blank')
        } else if (name === 'facebook') {
            window.open(`https://www.facebook.com/dialog/share?app_id=${FBappID}&href=${href}&quote=${shared_text}`, '_blank')
        }
    }

    return (
        <div className={styles.share}>
            {toggle && <div className={styles.social_medias}>
                <button className={styles.social_button} onClick={() => shareTo('facebook')}>
                    <Icon className={styles.social_icon} icon="fb" />
                </button>
                <button className={styles.social_button} onClick={() => shareTo('linkedIn')}>
                    <Icon className={styles.social_icon} icon="linked-in" />
                </button>
                <button className={styles.social_button} onClick={() => shareTo('twitter')}>
                    <Icon className={styles.social_icon} icon="twitter" />
                </button>
            </div>}
            <button className={styles.button} onClick={() => setToggle(!toggle)}>
                <Icon className={styles.share_icon} icon={toggle ? "close" : "share"} />
            </button>
        </div>
    )
}

export default memo(Share);