import { useState, useEffect } from 'react';
import { RootState } from '../../index';
import { useSelector, shallowEqual } from 'react-redux';
import { LocalVideoTrack, LocalAudioTrack } from 'twilio-video';

type track = LocalVideoTrack | LocalAudioTrack | null

const usePublishTrack = (track: track) => {
    const [isPublished, setPublished] = useState(false);
    const { room } = useSelector(({ meeting }: RootState) => ({ room: meeting.room }), shallowEqual);

    useEffect(() => {
        if (track && room) {
            room.localParticipant.publishTrack(track, { priority: 'high' }).then(() => {
                setPublished(true);
            }).catch((error) => {
                console.error(`usePublishTrack | Failed to publish Local${track.kind}Track:`, error);
                alert(`Sorry could not publish the ${track.kind} to the room: ${error.message}`)
            });
            return () => {
                room.localParticipant.tracks.forEach((p) => {
                    if (p.track.kind === track.kind) {
                        p.unpublish();
                        setPublished(false);
                    }
                });
            }
        }
    }, [room, track]);

    return [isPublished]
}

export default usePublishTrack;