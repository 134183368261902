import { FC, memo, Dispatch, SetStateAction, useEffect } from 'react';
import Button from '../../meeting-rooms/controls/button';
import Settings from '../../meeting-rooms/controls/menu-settings';
import MenuButton from '../../meeting-rooms/controls/menu-button';
import useLocalVideoTrack from "../../../../../../hooks/use-local-video-track";

interface Props {
    setError: Dispatch<SetStateAction<any>>
    setTrack: Dispatch<SetStateAction<any>>
}

const VideoControl: FC<Props> = ({ setError, setTrack }) => {
    const { track, state, handleTrack, devices, selected, handleChange } = useLocalVideoTrack();

    useEffect(() => setTrack(track), [setTrack, track]);
    useEffect(() => {
        setError((prev: any) => {
            if (state === "prompt") return { ...prev, camera: "Waiting for camera permission" }
            if (state === "denied") return { ...prev, camera: "Please allow access to your camera" }
            return { ...prev, camera: null }
        });
    }, [setError, state]);

    if (devices.length) {
        return (
            <MenuButton onClick={handleTrack} icon={track ? "video" : "video-mute"}>
                <Settings {...{ title: "Select a Camera", devices, selected, handleChange }} />
            </MenuButton>
        )
    } else {
        return (
            <Button style={{ background: "#C0372A" }} disabled={true} icon="video-mute" />
        )
    }
}

export default memo(VideoControl);