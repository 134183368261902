import { AxiosError } from 'axios'
import { LocalVideoTrack, RemoteVideoTrack } from 'twilio-video';
import { Room, RemoteParticipant, LocalParticipant } from 'twilio-video';

export const DOMINANT_SPEAKER_CHANGED = "DOMINANT-SPEAKER-CHANGED";
export const JOINED_MEETING_FULFILLED = "JOINED-MEETING-FULFILLED";
export const JOINED_MEETING_REJECTED = "JOINED-MEETING-REJECTED";
export const JOINED_MEETING_PENDING = "JOINED-MEETING-PENDING";
export const JOINED_MEETING = "JOINED-MEETING";
export const DISCONNECTED_FROM_MEETING = "DISCONNECTED-FROM-MEETING";


export const UPDATE_PARTICIPANT = "UPDATE-PARTICIPANT";
export const PARTICIPANT_JOINED = "PARTICIPANT-JOINED";
export const PARTICIPANT_LEFT = "PARTICIPANT-LEFT";
export const HAND_LOWERED = "HAND-LOWERED";
export const HAND_RAISED = "HAND-RAISED";

export interface IUser {
    user_id: string
    profile: string
    last_name: string
    first_name: string
    raised_hand?: boolean
    type: "guest" | "participant" | "moderator"
}

export interface IUserHash {
    [key: string]: IUser
}

export interface IParticipant extends IUser {
    sid: string
    audio?: boolean
    videoTrack?: RemoteVideoTrack | LocalVideoTrack
}

export interface IMeeting {
    room?: Room
    loading: Boolean
    users: IUserHash
    error: string | null
    total_guests: number
    guests: IParticipant[]
    total_participants: number
    participants: IParticipant[]
    moderator: IParticipant | null
    dominant_speaker: RemoteParticipant | null
}

export interface DispatchJoinMeeting {
    type: typeof JOINED_MEETING
    payload: Promise<{ room: Room; users: IUser[]; }>
}

export interface DispatchDisconnectedFromMeeting {
    type: typeof DISCONNECTED_FROM_MEETING
}

export interface DispatchDominantSpeakerChanged {
    type: typeof DOMINANT_SPEAKER_CHANGED
    payload: RemoteParticipant
}

export interface JoinMeetingLoading {
    type: typeof JOINED_MEETING_PENDING
}

export interface JoinMeetingRejected {
    type: typeof JOINED_MEETING_REJECTED
    payload: AxiosError
}

export interface JoinMeetingFulfilled {
    type: typeof JOINED_MEETING_FULFILLED
    payload: {
        room: Room
        users: IUser[]
    }
}

export interface DisconnectedFromMeeting {
    type: typeof DISCONNECTED_FROM_MEETING
}

export interface DominantSpeakerChanged {
    type: typeof DOMINANT_SPEAKER_CHANGED
    payload: RemoteParticipant
}

export interface DispatchParticipantJoined {
    type: typeof PARTICIPANT_JOINED
    payload: RemoteParticipant
}

export interface ParticipantJoined {
    type: typeof PARTICIPANT_JOINED
    payload: RemoteParticipant
}

export interface DispatchParticipantLeft {
    type: typeof PARTICIPANT_LEFT
    payload: RemoteParticipant
}

export interface ParticipantLeft {
    type: typeof PARTICIPANT_LEFT
    payload: RemoteParticipant
}

export interface DispatchUpdateParticipant {
    type: typeof UPDATE_PARTICIPANT
    payload: RemoteParticipant | LocalParticipant
}

export interface UpdateParticipant {
    type: typeof UPDATE_PARTICIPANT
    payload: RemoteParticipant | LocalParticipant
}

export interface handLowered {
    type: typeof HAND_LOWERED
    payload: string
}


export interface handRaised {
    type: typeof HAND_RAISED
    payload: {
        name: string
        user_id: string
    }
}


export type IMeetingActions = JoinMeetingLoading | JoinMeetingRejected | JoinMeetingFulfilled | DisconnectedFromMeeting | DominantSpeakerChanged | ParticipantJoined | ParticipantLeft | UpdateParticipant | handRaised | handLowered