export const NEW_MESSAGE = "NEW-MESSAGE";
export const SEND_MESSAGE = "SEND-MESSAGE";
export const FETCH_MESSAGES = "FETCH-MESSAGES";
export const SEND_MESSAGE_PENDING = "SEND-MESSAGE-PENDING";
export const SEND_MESSAGE_REJECTED = "SEND-MESSAGE-REJECTED";
export const SEND_MESSAGE_FULFILLED = "SEND-MESSAGE-FULFILLED";
export const RESEND_MESSAGE_PENDING = "RESEND-MESSAGE-PENDING";
export const FETCH_MESSAGES_PENDING = "FETCH-MESSAGES-PENDING";
export const FETCH_MESSAGES_REJECTED = "FETCH-MESSAGES-REJECTED";
export const FETCH_MESSAGES_FULFILLED = "FETCH-MESSAGES-FULFILLED";

export interface IMessage {
    first_name?: string
    last_name?: string
    message: string
    profile: string
    user_id?: string
    time_stamp: number
    status?: "pending" | "rejected"
}

export interface DispatchFetchMessages {
    type: typeof FETCH_MESSAGES
    payload: Promise<any>
}

export interface FetchMessagesPending {
    type: typeof FETCH_MESSAGES_PENDING
}
export interface FetchMessagesRejected {
    type: typeof FETCH_MESSAGES_REJECTED
}
export interface FetchMessagesFulfilled {
    type: typeof FETCH_MESSAGES_FULFILLED
    payload: {
        data: {
            page?: string
            messages: IMessage[]
        }
    }
}

export interface sendMessageFulfilled {
    type: typeof SEND_MESSAGE_FULFILLED
    payload: { data: IMessage }
}
export interface sendMessagePending {
    type: typeof SEND_MESSAGE_PENDING
    payload: IMessage
}
export interface resendMessagePending {
    type: typeof RESEND_MESSAGE_PENDING
    payload: IMessage
}
export interface sendMessageRejected {
    type: typeof SEND_MESSAGE_REJECTED
    payload: number
}
export interface ReceivedNewMessage {
    type: typeof NEW_MESSAGE
    payload: IMessage
}

export type DispatchSendMessage = sendMessagePending | sendMessageFulfilled | sendMessageRejected | resendMessagePending
export type IActions = FetchMessagesFulfilled | FetchMessagesPending | FetchMessagesRejected | sendMessagePending | sendMessageFulfilled | sendMessageRejected | ReceivedNewMessage | resendMessagePending