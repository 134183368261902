import { LocalVideoTrack, LocalAudioTrack } from 'twilio-video';
import { useEffect, useState } from 'react';

const usePlayLocalTrack = (track: LocalVideoTrack | LocalAudioTrack | null, ref: any): [boolean] => {
    const [isPlaying, setPlaying] = useState(false);

    useEffect(() => {
        if (track) {
            const elem = track.attach(ref.current);
            const onCanPlay = () => elem.play();
            const onPlay = () => setPlaying(true);
            elem.addEventListener('play', onPlay);
            elem.addEventListener('canplay', onCanPlay);
            track.addListener("started", () => setPlaying(true))
            track.addListener("stopped", () => setPlaying(false))
            return () => {
                track.detach();
                setPlaying(false);
                elem.removeEventListener('play', onPlay);
                elem.removeEventListener('canplay', onCanPlay);
            };
        }
    }, [ref, track]);

    return [isPlaying]
}

export default usePlayLocalTrack;