import { combineReducers } from "redux";

import userReducer from './user-reducer';
import eventReducer from './event-reducer';
import meetingReducer from './meeting-reducer';
import messagesReducer from './messages-reducer';
import RaisedHandsReducer from './raised-hands-reducer';

const rootReducer = combineReducers({
    user: userReducer,
    event: eventReducer,
    meeting: meetingReducer,
    messages: messagesReducer,
    raised_hands: RaisedHandsReducer,
});

// const reducer = (state, action) => {
//     if (action.type === "LOGGED-OUT") {
//         state = { user: { event_id: state.user.event_id } }
//     }
//     return appReducer(state, action);
// };

export default rootReducer;