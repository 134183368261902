import { FC, memo } from 'react';
import Button from './button';
import Settings from './menu-settings';
import MenuButton from './menu-button';
import usePublishTrack from "../../../../../../hooks/use-publish-track";
import useLocalVideoTrack from "../../../../../../hooks/use-local-video-track";

const VideoControl: FC = () => {
    const { track, handleTrack, devices, selected, handleChange } = useLocalVideoTrack();
    const [isPublished] = usePublishTrack(track);

    if (devices.length) {
        return (
            <MenuButton onClick={handleTrack} icon={isPublished ? "video" : "video-mute"}>
                <Settings {...{ title: "Select a Camera", devices, selected, handleChange }} />
            </MenuButton>
        )
    } else {
        return (
            <Button style={{ background: "#C0372A" }} disabled={true} icon="video-mute" />
        )
    }
}

export default memo(VideoControl);